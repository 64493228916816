import React from 'react';
import { Navigate, useRoutes } from 'react-router';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import loadable from '@loadable/component';

const DueDiligence = loadable(() => import('./DueDiligence'));
const Locations = loadable(() => import('./Locations'));
const HistoryIO = loadable(() => import('./HistoryIO'));
const HistorySn = loadable(() => import('./HistorySn'));
const DayCloseRoutes = loadable(() => import('./DayClose'));
const DealibirdSkus = loadable(() => import('./DealibirdSkus'));
const NotFound = loadable(() => import('routes/Errors/404'));

const InventoryRoutes = () => {
  const routes = [
    { index: true, element: <Navigate to="locations" replace={true} /> },
    { path: 'due-diligence', element: <DueDiligence /> },
    { path: 'locations', element: <Locations /> },
    { path: 'history-inout', element: <HistoryIO /> },
    { path: 'history-sn', element: <HistorySn /> },
    { path: 'day-close/*', element: <DayCloseRoutes /> },
    { path: 'dealibird-skus', element: <DealibirdSkus /> },
    { path: '*', element: <NotFound /> },
  ];

  return <PageContainer>{useRoutes(routes)}</PageContainer>;
};

export default InventoryRoutes;
