import {
  SET_PACKING_TABLE,
  SET_SETTING_SHIPMENT_TABLE,
  SET_LOCATIONS,
  RESET_LOCATIONS,
  SET_LOCATIONS_SUMMARY,
  EDIT_LOCATION,
  EDIT_LOCATIONS,
  EDIT_LOCATIONS_GROUP,
  DELETE_LOCATIONS,
  DELETE_ACCOUNT,
  EDIT_ACCOUNT_INFO,
  SET_PACKING_TABLE_BY_ID,
  GET_SELLER_INFO,
  GET_PRODUCT_INFO,
  GET_USERS_INFO,
  GET_ALL_SHIPMENTTABLE_ASSIGNED_SELLERS,
  SET_ONE_SHIPMENT_TABLE,
  SET_LOCATIONS_TYPES,
  SET_LOCATIONS_LANES,
  ADD_NEW_LOCATION,
  ADD_NEW_LOCATIONS,
} from '@jumbo/constants/ActionTypes';

const INIT_STATE = {
  packing_table: [],
  packing_table_by_id: [],
  seller_info: [],
  product_info: [],

  shipment_tables: [],
  shipment_table: null,
  users_info: [],
  shipment_table_assigned_sellers: [],

  locations: [],
  locations_summary: [],
  locations_types: [],
  locations_group: [],
  locations_lanes: [],

  user_accounts: [],
};

export default (state = INIT_STATE, action, dispatch) => {
  switch (action.type) {
    // User
    case DELETE_ACCOUNT: {
      const _user_accounts = state.user_accounts.filter(p => p.id !== action.payload.id);
      return {
        ...state,
        user_accounts: _user_accounts,
      };
    }
    case EDIT_ACCOUNT_INFO: {
      const index = state.user_accounts.findIndex(p => p.id === action.payload.id);
      const _user_accounts = state.user_accounts;
      _user_accounts[index] = action.payload;
      return {
        ...state,
        user_accounts: _user_accounts,
      };
    }

    // 입고관리 > 포장 테이블 관리
    case SET_PACKING_TABLE: {
      return {
        ...state,
        packing_table: action.payload,
      };
    }

    case SET_PACKING_TABLE_BY_ID: {
      return {
        ...state,
        packing_table_by_id: action.payload,
      };
    }
    case GET_SELLER_INFO: {
      return {
        ...state,
        seller_info: action.payload,
      };
    }
    case GET_PRODUCT_INFO: {
      return {
        ...state,
        product_info: action.payload,
      };
    }

    // 설정관리 > 출고 > 출고테이블관리
    case SET_SETTING_SHIPMENT_TABLE: {
      return {
        ...state,
        shipment_tables: action.payload,
      };
    }
    case SET_ONE_SHIPMENT_TABLE: {
      return {
        ...state,
        shipment_table: action.payload,
      };
    }
    case GET_USERS_INFO: {
      return {
        ...state,
        users_info: action.payload,
      };
    }
    case GET_ALL_SHIPMENTTABLE_ASSIGNED_SELLERS: {
      return {
        ...state,
        shipment_table_assigned_sellers: action.payload,
      };
    }

    // Location
    case SET_LOCATIONS: {
      return {
        ...state,
        locations: action.payload,
      };
    }
    case RESET_LOCATIONS: {
      return {
        ...state,
        locations: [],
      };
    }
    case EDIT_LOCATION: {
      const _locations = state.locations;
      const targetLocation = _locations.find(({ id }) => id === action.payload.id);
      Object.assign(targetLocation, action.payload);

      return {
        ...state,
        locations: _locations,
      };
    }
    case EDIT_LOCATIONS: {
      const _locations = state.locations;
      action.payload.forEach(location => {
        const targetLocation = _locations.find(_location => _location.id === location.id);
        Object.assign(targetLocation, { ...location });
      });

      return {
        ...state,
        locations: _locations,
      };
    }
    case SET_LOCATIONS_SUMMARY: {
      return {
        ...state,
        locations_summary: action.payload,
      };
    }
    case SET_LOCATIONS_TYPES: {
      return {
        ...state,
        locations_types: action.payload,
      };
    }
    case SET_LOCATIONS_LANES: {
      return {
        ...state,
        locations_lanes: action.payload,
      };
    }
    case ADD_NEW_LOCATION: {
      const _locations = [...state.locations];
      _locations.push(action.payload);

      return {
        ...state,
        locations: _locations,
      };
    }
    case ADD_NEW_LOCATIONS: {
      const _locations = [...state.locations].concat(action.payload);

      return {
        ...state,
        locations: _locations,
      };
    }
    case EDIT_LOCATIONS_GROUP: {
      const index = state.locations.findIndex(p => p.id === action.payload.id);
      const _locations = state.locations;
      _locations[index].id = action.payload.id;
      return {
        ...state,
        locations_group: _locations,
      };
    }
    case DELETE_LOCATIONS: {
      const _locations = state.locations.filter(p => !action.payload.includes(p.id));
      return {
        ...state,
        locations: _locations,
      };
    }

    default:
      return state;
  }
};
