import { fetchError, fetchStart, fetchSuccess } from 'redux/actions';
import { setAuthUser, setForgetPassMailSent, updateLoadUser } from 'redux/actions/Auth';
import React from 'react';
import axios from './config';
import { getToken, removeToken, setToken } from 'api/GetToken';
import { pushHistory } from 'redux/actions/History';

const JWTAuth = {
  onRegister: ({ username, name, email, password, password_information }) => {
    return dispatch => {
      dispatch(fetchStart());
      axios
        .post('/auth/register', {
          username: username,
          name: name,
          email: email,
          password: password,
          password_information: password_information,
        })
        .then(({ data }) => {
          const { meta } = data;
          if (meta.result === 'success') {
            dispatch(fetchSuccess());
            window.alert('회원가입 되었습니다.');
            dispatch(pushHistory('/signin'));
          }
        })
        .catch(error => {
          window.alert(error.response.data.errors[0]);
          dispatch(fetchError());
        });
    };
  },
  onLogin: ({ login, password }) => {
    return dispatch => {
      dispatch(fetchStart());
      delete axios.defaults.headers.common['Authorization']; // 헤더의 Authorization 값을 삭제
      axios
        .post('/auth/login', {
          login: login,
          password: password,
        })
        .then(({ data }) => {
          const { data: body } = data;

          setToken(body.access_token);

          axios.defaults.headers.common['Authorization'] = 'Bearer ' + body.access_token;
          dispatch(fetchSuccess());
          dispatch(JWTAuth.getAuthUser(true, body.access_token));
        })
        .catch(error => {
          window.alert(error.response.data.errors[0]);
          dispatch(fetchError());
        });
    };
  },
  onLogout: () => {
    return dispatch => {
      dispatch(fetchStart());
      removeToken();
      dispatch(setAuthUser(null));
      dispatch(fetchSuccess());
    };
  },
  getAuthUser: (loaded = false, token) => {
    return dispatch => {
      if (!token) {
        const t = getToken();
        if (t) {
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + t;
        }
      }
      dispatch(fetchStart());
      dispatch(updateLoadUser(loaded));
      axios
        .get('users/me')
        .then(({ data }) => {
          const { data: body, meta } = data;
          if (meta.result === 'success') {
            dispatch(fetchSuccess());
            dispatch(setAuthUser(body));
          } else {
            dispatch(fetchError(meta.message));
            dispatch(updateLoadUser(true));
          }
        })
        .catch(error => {
          dispatch(updateLoadUser(true));
          dispatch(fetchError());
        });
    };
  },

  onForgotPassword: () => {
    return dispatch => {
      dispatch(fetchStart());

      setTimeout(() => {
        dispatch(setForgetPassMailSent(true));
        dispatch(fetchSuccess());
      }, 300);
    };
  },
  getSocialMediaIcons: () => {
    return <React.Fragment> </React.Fragment>;
  },
};

export default JWTAuth;
