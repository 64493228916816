import { SET_REAL_PACKING_STATUS } from '@jumbo/constants/ActionTypes';

const INIT_STATE = {
  status: null,
  code: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_REAL_PACKING_STATUS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
};
