import axios from 'axios';

export const SlackService = {
  send: async (title, error_json) => {
    if (!['qa', 'staging', 'production'].includes(process.env.REACT_APP_ENV)) {
      return false;
    }

    try {
      await axios
        .post(process.env.REACT_APP_API_HOST + '/slacks/error/matrix_console', {
          title: title,
          error_json: error_json,
          path: window.location.href,
        })
        .then(({ data }) => {
          // eslint-disable-next-line no-console
          console.log(data);
        });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  },
};
