import React from 'react';
import { Box } from '@mui/material';
import PageChildrenBtn from 'components/Common/PageChildrenBtn';
import { useLocation } from 'react-router-dom';
import { findMyChildren, whoAmI } from '@jumbo/components/AppLayout/partials/menus';
import { PageHeader, PageBreadcrumbs } from '../../@jumbo/components/PageComponents';

const PageChildrenBtnWrap = () => {
  const location = useLocation();

  const pathname = location.pathname;
  const depth = pathname.split('/').length - 1; // 가장 앞 / 는 제외

  // 하위 메뉴 그리기
  const rendering_page_children_btn = () => {
    const founded_children_list = [];

    for (let i = depth; i > 0; i--) {
      const path_parts = pathname.split('/');
      const parent_path = path_parts.splice(0, path_parts.length - i).join('/');
      const founded_children = findMyChildren(parent_path);

      // 자식을 찾았을 경우!!
      if (!!founded_children) founded_children_list.push(<PageChildrenBtn key={i} children_menu={founded_children} />);
    }
    return founded_children_list;
  };

  // header breadcrumb 부분 필요해지면 사용 요망 (22년 3월에 제거된 영역이지만 혹시몰라서 추가)
  //eslint-disable-next-line
  const rendering_breadcrumb = () => {
    // heading, breadcrumbs 영역
    // 메뉴와 메뉴 부모 찾기
    const { menu, parents } = whoAmI(pathname);
    const current_menu_name = menu.name?.props?.children;
    const breadcrumbs = parents.map(el => {
      return { label: el.name?.props?.children, link: el.link };
    });

    if (!!current_menu_name) breadcrumbs.push({ label: current_menu_name, link: menu.link });

    // version1 - heading + breadcrumb
    return <PageHeader heading={current_menu_name} breadcrumbComponent={<PageBreadcrumbs items={breadcrumbs} />} />;

    // version2 - only breadcrumb
    // return <PageHeader heading={''} breadcrumbComponent={<PageBreadcrumbs items={breadcrumbs} />} />;
  };

  return (
    <Box style={{ background: 'white' }} mx={-2}>
      {/*{rendering_breadcrumb()}*/}
      {rendering_page_children_btn()}
    </Box>
  );
};

export default PageChildrenBtnWrap;
