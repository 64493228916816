import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import App from './App';
// import * as serviceWorker from './serviceWorker';
// import 'react-app-polyfill/ie11';
// import 'react-app-polyfill/stable';

// import '@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
// import '@ag-grid-enterprise/all-modules/dist/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise/styles/ag-grid.css';
import 'ag-grid-enterprise/styles/ag-theme-alpine.css';

import { LicenseManager } from 'ag-grid-enterprise';
LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_LICENSE_KEY);

const _sentry_allow_env = ['qa', 'staging', 'production'];

if (_sentry_allow_env.includes(process.env.REACT_APP_ENV)) {
  const _sentry_dsn_qa = 'https://f264aa9b5cfe4424b757527f2f8a79ff@o311364.ingest.sentry.io/4504632888655872';
  const _sentry_dsn_production = 'https://ce6d0b62500e4ba48376007fdf8531c9@o311364.ingest.sentry.io/4504562930155520';

  Sentry.init({
    dsn: process.env.REACT_APP_ENV === 'qa' ? _sentry_dsn_qa : _sentry_dsn_production,
    release: '0.1.0',
    environment: process.env.REACT_APP_ENV,
    normalizeDepth: 6,
    integrations: [new Sentry.Integrations.Breadcrumbs({ console: true }), new BrowserTracing()],
  });
}

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
