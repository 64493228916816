import React, { useContext, useImperativeHandle } from 'react';
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LayoutContext from '../LayoutContext';

const contentRef = React.createRef();

const SidebarToggleHandler = React.forwardRef(function SidebarToggleHandler(props, ref) {
  const { isSidebarOpen, setSidebarOpen } = useContext(LayoutContext);

  useImperativeHandle(ref, () => ({
    isOpen: isSidebarOpen,
  }));

  return (
    <IconButton className="Cmt-toggle-menu" ref={contentRef} onClick={() => setSidebarOpen(!isSidebarOpen)} {...props}>
      <MenuIcon />
    </IconButton>
  );
});

export default SidebarToggleHandler;
