import {
  SET_WHOLESALE_RETURN, // 반품관리
  SET_WHOLESALE_RETURN_RECEIPT, // 도매 반품 접수
  SET_WHOLESALE_RETURN_RECEIPT_PRINT_PICKING_LIST,
  RESET_WHOLESALE_RETURN_RECEIPT_PRINT_PICKING_LIST,
  SET_WHOLESALE_RETURN_STATUS,
  UPDATE_WHOLESALE_RETURN_RECEIPT, // 도매 반품 현황
} from '@jumbo/constants/ActionTypes';

const INIT_STATE = {
  wholesale_return_list: [],
  wholesale_return_receipt_list: [],
  wholesale_return_receipt_print_picking_list: [],
  wholesale_return_status_list: [],
};

export default (state = INIT_STATE, action, dispatch) => {
  switch (action.type) {
    // due diligence
    case SET_WHOLESALE_RETURN: {
      return {
        ...state,
        wholesale_return_list: action.payload,
      };
    }
    case SET_WHOLESALE_RETURN_RECEIPT: {
      return {
        ...state,
        wholesale_return_receipt_list: action.payload,
      };
    }
    case UPDATE_WHOLESALE_RETURN_RECEIPT: {
      state.wholesale_return_receipt_list.forEach(e =>
        action.payload.forEach(p => e.id === p.id && (e.is_printed_picking_list_name = 'Y')),
      );

      return {
        ...state,
      };
    }
    case SET_WHOLESALE_RETURN_RECEIPT_PRINT_PICKING_LIST: {
      return {
        ...state,
        wholesale_return_receipt_print_picking_list: action.payload,
      };
    }
    case RESET_WHOLESALE_RETURN_RECEIPT_PRINT_PICKING_LIST: {
      return {
        ...state,
        wholesale_return_receipt_print_picking_list: [],
      };
    }
    case SET_WHOLESALE_RETURN_STATUS: {
      return {
        ...state,
        wholesale_return_status_list: action.payload,
      };
    }
    default:
      return state;
  }
};
