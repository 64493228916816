import React from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import agGridStyles from 'components/Table/AgGrid.style';

const useStyles = makeStyles(() => ({
  pageFull: {
    width: '100%',
  },
}));

const PageContainer = ({
  // heading, breadcrumbs,
  children,
  className,
  restProps,
}) => {
  const classes = useStyles();

  // 모든 페이지에서 사용하는 root 클래스를 기본값으로 추가 (미사용 페이지는 제외)
  if (!className) className = agGridStyles().root;

  return (
    // <Slide in={true} direction="up" mountOnEnter unmountOnExit>
    <Box className={clsx(classes.pageFull, className)} {...restProps}>
      {/* heading 부분 PageChildrenBtnWrap.js 으로 이동 */}
      {children}
    </Box>
    // </Slide>
  );
};

export default PageContainer;
