import {
  SET_WAREHOUSING_INSPECTION_PURCHASE, // 신규 주문
  SET_WAREHOUSING_INSPECTION_MISONG, // 미송 주문
  EDIT_PRICE_WAREHOUSING_INSPECTION_PURCHASE, // 단가 변경
  EDIT_STOCK_WAREHOUSING_INSPECTION_PURCHASE,
  EDIT_JANGKKIGROUP_WAREHOUSING_INSPECTION_PURCHASE,
  EDIT_PIECEGROUP_WAREHOUSING_INSPECTION_PURCHASE,
  EDIT_INSTOCKGROUP_WAREHOUSING_INSPECTION_PURCHASE,
  DELETE_WAREHOUSING_DETAIL_INFORMATION,
  SET_WAREHOUSING_WHOLESALE_LIST, //도매처검색리스트
  PUT_CONFIRM_ALL,
  PUT_CONFIRM_BY_ID,
  PUT_CONFIRM_BY_OWNER,
  SET_WAREHOUSING_DETAIL_INFORMATION,
  SET_WAREHOUSING_FIXED,
  SET_WAREHOUSING_FIXED_SUMMARY,
  SET_WAREHOUSING_TRACKING, // 입고 진행현황
  CANCEL_FORCESTOCK_WAREHOUSING_TRACKING,
  SET_WAREHOUSING_PURCHASE,
  SET_WAREHOUSING_STANDBY,
  GET_PACKING_TABLE_PRODUCT,
  GET_REPAIR_TABLE_PRODUCT,
  GET_WHOLESALE_RETURN_TABLE_PRODUCT,
  GET_SELF_WAREHOUSING_PURCHASE_LIST,
  GET_SELF_WAREHOUSING_WAITING_LIST,
  EDIT_SELF_WAREHOUSING_REQUEST_DETAIL_LIST,
  GET_SELF_WAREHOUSING_REQUEST_DETAIL_LIST,
  GET_SELF_WAREHOUSING_REQUEST_DETAIL,
  GET_SELF_WAREHOUSING_PER_PRODUCT_LIST,
  GET_SELF_WAREHOUSING_PER_ROTATE_LIST,
  SET_CHANGED_INSTOCK,
  GET_ISSUE_SUPPLIER_RETURN_HISTORY,
  GET_SELLER_CHECK_TABLE_PRODUCT,
  GET_SELLER_CONFIRM_TABLE_ARRIVE,
  GET_WHOLESALE_RETURN_TABLE_ARRIVE,
  GET_PACKING_TABLE_ARRIVE,
  GET_REPAIR_TABLE_ARRIVE,
  SET_SORTING_WAREHOUSING_TRACKING_CONFIRMABLE,
  SET_SORTING_WAREHOUSING_TRACKING_CONFIRMDISABLE,
  GET_WHOLESALE_RETURN_PRODUCT_HISTORY,
  GET_SELLER_CONFIRM_PRODUCT_HISTORY,
  GET_RESET_TABLE_MOVE_INFO,
  GET_AFTER_WHOLESALE_RETURN_TABLE_ARRIVE,
  GET_SELLER_CONFIRM_TABLE_ARRIVE_HISTORY,
  GET_SELLER_CONFIRM_TABLE_ARRIVE_MANY,
  GET_SELLER_CONFIRM_TABLE_ARRIVE_MANY_UNSELECTED_LIST,
  GET_SELLER_CONFIRM_TABLE_ARRIVE_MANY_SELECTED_LIST,
  GET_WHOLESALE_RETURN_TABLE_ARRIVE_MANY,
  GET_WHOLESALE_RETURN_TABLE_ARRIVE_MANY_UNSELECTED_LIST,
  GET_WHOLESALE_RETURN_TABLE_ARRIVE_MANY_SELECTED_LIST,
  GET_WHOLESALE_RETURN_TABLE_ARRIVE_HISTORY,
  GET_DIRECT_SELLER_CONFIRM_TABLE_ARRIVE,
  GET_DIRECT_SELLER_CONFIRM_TABLE_PRODUCT,
  SET_WAREHOUSING_TRACKING_FILTER,
  SET_WAREHOUSING_TRACKING_PAGINATION,
  GET_RETURN_PRODUCT_MANAGEMENT,
  DELETE_RETURN_PRODUCT_MANAGEMENT,
  DELETE_SELLER_CONFIRM_TABLE_ARRIVE_MANY,
  DELETE_DIRECT_SELLER_CONFIRM_TABLE_ARRIVE,
} from '@jumbo/constants/ActionTypes';

// ActionTypes.js 의 포화상태를 방지하기 위해 분리 -> 전용 상수는 각 액션에서 정의
export const types = {
  SELF_WAREHOUSING_PURCHASE_LIST_RELOAD: 'self_warehousing_purchase_list_reload', // history push 작동방식 변경에 따라 리로드에 사용
};
export const wareHousingTypes = types;

export const setWarehousingPurchase = products => {
  return dispatch => {
    dispatch({
      type: SET_WAREHOUSING_PURCHASE,
      payload: products,
    });
  };
};

export const setWarehousingStandby = products => {
  return dispatch => {
    dispatch({
      type: SET_WAREHOUSING_STANDBY,
      payload: products,
    });
  };
};

/**
 * 입고 검수 진행
 */
export const setWarehousingInspectionPurchase = warehousing_inspection_purchase => {
  return dispatch => {
    dispatch({
      type: SET_WAREHOUSING_INSPECTION_PURCHASE,
      payload: warehousing_inspection_purchase,
    });
  };
};

export const editPriceWarehousingInspectionPurchase = warehousing_inspection_purchase => {
  return dispatch => {
    dispatch({
      type: EDIT_PRICE_WAREHOUSING_INSPECTION_PURCHASE,
      payload: warehousing_inspection_purchase,
    });
  };
};
export const editStockWarehousingInspectionPurchase = warehousing_inspection_stock => {
  return dispatch => {
    dispatch({
      type: EDIT_STOCK_WAREHOUSING_INSPECTION_PURCHASE,
      payload: warehousing_inspection_stock,
    });
  };
};
export const editJangkkigroupWarehousingInspectionPurchase = warehousing_inspection_purchase => {
  return dispatch => {
    dispatch({
      type: EDIT_JANGKKIGROUP_WAREHOUSING_INSPECTION_PURCHASE,
      payload: warehousing_inspection_purchase,
    });
  };
};
export const editPiecegroupWarehousingInspectionPurchase = warehousing_inspection_purchase => {
  return dispatch => {
    dispatch({
      type: EDIT_PIECEGROUP_WAREHOUSING_INSPECTION_PURCHASE,
      payload: warehousing_inspection_purchase,
    });
  };
};
export const editInstockgroupWarehousingInspectionPurchase = warehousing_inspection_purchase => {
  return dispatch => {
    dispatch({
      type: EDIT_INSTOCKGROUP_WAREHOUSING_INSPECTION_PURCHASE,
      payload: warehousing_inspection_purchase,
    });
  };
};

export const setWarehousingInspectionMisong = warehousing_inspection_misong => {
  return dispatch => {
    dispatch({
      type: SET_WAREHOUSING_INSPECTION_MISONG,
      payload: warehousing_inspection_misong,
    });
  };
};

export const putConfirmById = summary => {
  return dispatch => {
    dispatch({
      type: PUT_CONFIRM_BY_ID,
      payload: summary,
    });
  };
};

export const putConfirmByOwner = summary => {
  return dispatch => {
    dispatch({
      type: PUT_CONFIRM_BY_OWNER,
      payload: summary,
    });
  };
};

export const putConfirmAll = summary => {
  return dispatch => {
    dispatch({
      type: PUT_CONFIRM_ALL,
      payload: summary,
    });
  };
};

export const setWarehousingFixed = products => {
  return dispatch => {
    dispatch({
      type: SET_WAREHOUSING_FIXED,
      payload: products,
    });
  };
};
export const setWarehousingFixedSummary = summary => {
  return dispatch => {
    dispatch({
      type: SET_WAREHOUSING_FIXED_SUMMARY,
      payload: summary,
    });
  };
};
export const setWarehousingWholesaleList = warehousing_wholesale_list => {
  return dispatch => {
    dispatch({
      type: SET_WAREHOUSING_WHOLESALE_LIST,
      payload: warehousing_wholesale_list,
    });
  };
};

/**
 * 입고 진행현황
 */
export const setWarehousingTracking = warehousing_tracking => {
  return dispatch => {
    dispatch({
      type: SET_WAREHOUSING_TRACKING,
      payload: warehousing_tracking,
    });
  };
};
export const setWarehousingTrackingFilter = filter => {
  return dispatch => {
    dispatch({
      type: SET_WAREHOUSING_TRACKING_FILTER,
      payload: filter,
    });
  };
};

export const setWarehousingTrackingPagination = page_info => {
  return dispatch => {
    dispatch({
      type: SET_WAREHOUSING_TRACKING_PAGINATION,
      payload: page_info,
    });
  };
};
export const setSortingWarehousingTrackingConfirmable = () => {
  return dispatch => {
    dispatch({
      type: SET_SORTING_WAREHOUSING_TRACKING_CONFIRMABLE,
      payload: null,
    });
  };
};
export const setSortingWarehousingTrackingConfirmdisable = () => {
  return dispatch => {
    dispatch({
      type: SET_SORTING_WAREHOUSING_TRACKING_CONFIRMDISABLE,
      payload: null,
    });
  };
};
export const cancelForceStockgroupWarehousingTracking = warehousing_tracking => {
  return dispatch => {
    dispatch({
      type: CANCEL_FORCESTOCK_WAREHOUSING_TRACKING,
      payload: warehousing_tracking,
    });
  };
};

export const setWarehousingDetailInfo = detail => {
  return dispatch => {
    dispatch({
      type: SET_WAREHOUSING_DETAIL_INFORMATION,
      payload: detail,
    });
  };
};

export const deleteWarehousingDetailInfo = () => {
  return dispatch => {
    dispatch({
      type: DELETE_WAREHOUSING_DETAIL_INFORMATION,
      payload: null,
    });
  };
};
//입고상품이동
export const getPackingTableArrive = product_list => {
  return dispatch => {
    dispatch({
      type: GET_PACKING_TABLE_ARRIVE,
      payload: product_list,
    });
  };
};

export const getPackingTableProduct = product_list => {
  return dispatch => {
    dispatch({
      type: GET_PACKING_TABLE_PRODUCT,
      payload: product_list,
    });
  };
};

export const getRepairTableArrive = product_list => {
  return dispatch => {
    dispatch({
      type: GET_REPAIR_TABLE_ARRIVE,
      payload: product_list,
    });
  };
};
// 셀러 수선 테이블 상품
export const getRepairTableProduct = product_list => {
  return dispatch => {
    dispatch({
      type: GET_REPAIR_TABLE_PRODUCT,
      payload: product_list,
    });
  };
};
// 도매 반품 테이블 상품
export const getWholesaleReturnTableProduct = product_list => {
  return dispatch => {
    dispatch({
      type: GET_WHOLESALE_RETURN_TABLE_PRODUCT,
      payload: product_list,
    });
  };
};
//셀러 확인 테이블 도착
export const getSellerConfrimTableArrive = data => {
  return dispatch => {
    dispatch({
      type: GET_SELLER_CONFIRM_TABLE_ARRIVE,
      payload: data,
    });
  };
};
//셀러확인테이블 3개월 히스토리
export const getSellerConfirmTableArriveHistory = data => {
  return dispatch => {
    dispatch({
      type: GET_SELLER_CONFIRM_TABLE_ARRIVE_HISTORY,
      payload: data,
    });
  };
};
//셀러 확인 테이블 도착 대량이동 (전체 데이터)
export const getSellerConfirmTableArriveMany = data => {
  return dispatch => {
    dispatch({
      type: GET_SELLER_CONFIRM_TABLE_ARRIVE_MANY,
      payload: data,
    });
  };
};

//셀러 확인 테이블 도착 대량이동 (선택 가능 s/n 리스트)
export const getSellerConfirmTableArriveManyUnSelectedList = list => {
  return dispatch => {
    dispatch({
      type: GET_SELLER_CONFIRM_TABLE_ARRIVE_MANY_UNSELECTED_LIST,
      payload: list,
    });
  };
};
//셀러 확인 테이블 도착 대량이동 (대상테이블 s/n 리스트)
export const getSellerConfirmTableArriveManySelectedList = list => {
  return dispatch => {
    dispatch({
      type: GET_SELLER_CONFIRM_TABLE_ARRIVE_MANY_SELECTED_LIST,
      payload: list,
    });
  };
};
export const getIssueSupplierReturnHistory = history => {
  return dispatch => {
    dispatch({
      type: GET_ISSUE_SUPPLIER_RETURN_HISTORY,
      payload: history,
    });
  };
};
export const getSellerCheckTableProduct = product_list => {
  return dispatch => {
    dispatch({
      type: GET_SELLER_CHECK_TABLE_PRODUCT,
      payload: product_list,
    });
  };
};
export const getWholesaleReturnTableArrive = data => {
  return dispatch => {
    dispatch({
      type: GET_WHOLESALE_RETURN_TABLE_ARRIVE,
      payload: data,
    });
  };
};
export const getAfterWholesaleReturnTableArrive = data => {
  return dispatch => {
    dispatch({
      type: GET_AFTER_WHOLESALE_RETURN_TABLE_ARRIVE,
      payload: data,
    });
  };
};
export const getWholesaleReturnTableArriveHistory = data => {
  return dispatch => {
    dispatch({
      type: GET_WHOLESALE_RETURN_TABLE_ARRIVE_HISTORY,
      payload: data,
    });
  };
};
//도매 반품 테이블 도착 대량이동 (전체 데이터)
export const getWholesaleReturnTableArriveMany = data => {
  return dispatch => {
    dispatch({
      type: GET_WHOLESALE_RETURN_TABLE_ARRIVE_MANY,
      payload: data,
    });
  };
};

//도매 반품 테이블 도착 대량이동 (선택 가능 s/n 리스트)
export const getWholesaleReturnTableArriveManyUnSelectedList = list => {
  return dispatch => {
    dispatch({
      type: GET_WHOLESALE_RETURN_TABLE_ARRIVE_MANY_UNSELECTED_LIST,
      payload: list,
    });
  };
};
//도매 반품 테이블 도착 대량이동 (대상테이블 s/n 리스트)
export const getWholesaleReturnTableArriveManySelectedList = list => {
  return dispatch => {
    dispatch({
      type: GET_WHOLESALE_RETURN_TABLE_ARRIVE_MANY_SELECTED_LIST,
      payload: list,
    });
  };
};
//직접입고
export const getSelfWarehousingPurchaseList = product_list => {
  return dispatch => {
    dispatch({
      type: GET_SELF_WAREHOUSING_PURCHASE_LIST,
      payload: product_list,
    });
  };
};
export const getSelfWarehousingWaitingList = product_list => {
  return dispatch => {
    dispatch({
      type: GET_SELF_WAREHOUSING_WAITING_LIST,
      payload: product_list,
    });
  };
};
export const editSelfWarehousingRequestDetailList = product_info => {
  return dispatch => {
    dispatch({
      type: EDIT_SELF_WAREHOUSING_REQUEST_DETAIL_LIST,
      payload: product_info,
    });
  };
};
export const getSelfWarehousingRequestDetail = product_info => {
  return dispatch => {
    dispatch({
      type: GET_SELF_WAREHOUSING_REQUEST_DETAIL,
      payload: product_info,
    });
  };
};

export const getSelfWarehousingRequestDetailList = product_list => {
  return dispatch => {
    dispatch({
      type: GET_SELF_WAREHOUSING_REQUEST_DETAIL_LIST,
      payload: product_list,
    });
  };
};

export const getSelfWarehousingPerProductList = self_warehousing_per_product_list => {
  return dispatch => {
    dispatch({
      type: GET_SELF_WAREHOUSING_PER_PRODUCT_LIST,
      payload: self_warehousing_per_product_list,
    });
  };
};

export const getSelfWarehousingPerRotateList = self_warehousing_per_rotate_list => {
  return dispatch => {
    dispatch({
      type: GET_SELF_WAREHOUSING_PER_ROTATE_LIST,
      payload: self_warehousing_per_rotate_list,
    });
  };
};

export const setChangedInstock = product_info => {
  return dispatch => {
    dispatch({
      type: SET_CHANGED_INSTOCK,
      payload: product_info,
    });
  };
};
//직접입고 > 셀러 확인테이블 도착
export const getDirectSellerConfirmTableArrive = info => {
  return dispatch => {
    dispatch({
      type: GET_DIRECT_SELLER_CONFIRM_TABLE_ARRIVE,
      payload: info,
    });
  };
};

export const getDirectSellerConfirmTableProduct = list => {
  return dispatch => {
    dispatch({
      type: GET_DIRECT_SELLER_CONFIRM_TABLE_PRODUCT,
      payload: list,
    });
  };
};
//상품이동 과거이력
export const getWholesaleReturnTableProductHistory = history => {
  return dispatch => {
    dispatch({
      type: GET_WHOLESALE_RETURN_PRODUCT_HISTORY,
      payload: history,
    });
  };
};

export const getSellerConfirmTableProductHistory = history => {
  return dispatch => {
    dispatch({
      type: GET_SELLER_CONFIRM_PRODUCT_HISTORY,
      payload: history,
    });
  };
};

//테이블 이동전으로 변경
export const getResetTableMoveInfo = info => {
  return dispatch => {
    dispatch({
      type: GET_RESET_TABLE_MOVE_INFO,
      payload: info,
    });
  };
};

export const getReturnProductManagement = info => {
  return dispatch => {
    dispatch({
      type: GET_RETURN_PRODUCT_MANAGEMENT,
      payload: info,
    });
  };
};

export const deleteReturnProductManagement = () => {
  return dispatch => {
    dispatch({
      type: DELETE_RETURN_PRODUCT_MANAGEMENT,
      payload: null,
    });
  };
};

export const deleteSellerConfirmTableArriveMany = () => {
  return dispatch => {
    dispatch({
      type: DELETE_SELLER_CONFIRM_TABLE_ARRIVE_MANY,
      payload: null,
    });
  };
};

export const deleteDirectSellerConfirmTableArrive = () => {
  return dispatch => {
    dispatch({
      type: DELETE_DIRECT_SELLER_CONFIRM_TABLE_ARRIVE,
      payload: null,
    });
  };
};
