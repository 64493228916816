import {
  SET_MOVINGLOCATION_ALL_LIST, // 로케이션 일괄이동
  SET_MOVINGLOCATION_LIST, // 로케이션 일괄이동
  SET_MOVINGHISTORY_LIST, // 로케이션 이동내역
  SET_INVENTORY_CHECK, // 상품정보관리
  SET_INVENTORY_CHECK_DETAIL, // 상품정보관리 상세
  SET_SELLER_CHECK, // 셀러정보관리
  SET_SELLER_CHECK_DETAIL, // 셀러정보관리 상세
  SET_REQUEST_INCREASE, // 상품재고 증가요청조회
  SET_REQUEST_INCREASE_INVENTORY, // 상품재고 증가요청 상세
  SET_REQUEST_STATE,
  SET_REQUEST_DECREASE,
  SET_REQUEST_DECREASE_INVENTORY_LIST,
  SET_INVENTORY_CHECK_FILTER,
  SET_INVENTORY_CHECK_PAGINATION,
  SET_SELLER_SIMPLE_LIST, // 상품재고 증가요청 상태
} from '@jumbo/constants/ActionTypes';

const INIT_STATE = {
  moving_location_all: [],
  moving_location: [],
  moving_location_history: [],
  inventory_check: [],
  inventory_check_filter: null,
  inventory_check_pagination: null,
  seller_check: [],
  seller_simple_list: [],
  // seller_check_detail: {},
  request_increase: [],
  request_decrease: [],
  request_decrease_inventory: [],
  request_decrease_force_inout: [],
  request_decrease_product: [],
  purchase_detail: {},
  request_state: 'NOTHING',
};

export default (state = INIT_STATE, action, dispatch) => {
  switch (action.type) {
    case SET_MOVINGLOCATION_ALL_LIST: {
      return {
        ...state,
        moving_location_all: action.payload,
      };
    }
    case SET_MOVINGLOCATION_LIST: {
      return {
        ...state,
        moving_location: action.payload,
      };
    }
    case SET_MOVINGHISTORY_LIST: {
      return {
        ...state,
        moving_location_history: action.payload,
      };
    }
    //상품 정보관리
    case SET_INVENTORY_CHECK: {
      return {
        ...state,
        inventory_check: action.payload,
      };
    }
    case SET_INVENTORY_CHECK_FILTER: {
      return {
        ...state,
        inventory_check_filter: action.payload,
      };
    }

    case SET_INVENTORY_CHECK_PAGINATION: {
      return {
        ...state,
        inventory_check_pagination: action.payload,
      };
    }
    case SET_INVENTORY_CHECK_DETAIL: {
      return {
        ...state,
        inventory_check_detail: action.payload,
      };
    }
    case SET_SELLER_CHECK: {
      return {
        ...state,
        seller_check: action.payload,
      };
    }
    case SET_SELLER_SIMPLE_LIST: {
      return {
        ...state,
        seller_simple_list: action.payload,
      };
    }
    case SET_SELLER_CHECK_DETAIL: {
      return {
        ...state,
        seller_check_detail: action.payload,
      };
    }
    case SET_REQUEST_INCREASE: {
      return {
        ...state,
        request_increase: action.payload,
      };
    }
    case SET_REQUEST_INCREASE_INVENTORY: {
      return {
        ...state,
        purchase_detail: action.payload,
      };
    }
    case SET_REQUEST_DECREASE: {
      return {
        ...state,
        request_decrease: action.payload,
      };
    }
    case SET_REQUEST_DECREASE_INVENTORY_LIST: {
      return {
        ...state,
        request_decrease_inventory: action.payload.inventories,
        request_decrease_force_inout: action.payload.force_inout,
        request_decrease_product: action.payload.product,
      };
    }
    case SET_REQUEST_STATE: {
      return {
        ...state,
        request_state: action.payload,
      };
    }
    default:
      return state;
  }
};
