import { DialogContentText } from '@mui/material';
import React from 'react';
import { CommonDialog } from 'common/Modals/CommonDialog';
import commonModalStyle from 'common/Modals/styles/CommonModal.style';

/**
 * 공통 Alert 모달 (Dialog)
 *
 * @param rest 기본적인 CommonModal 옵션
 * @param message alert 메시지
 * @param onOk 확인 버튼 클릭시 실행될 함수
 * @param buttonColor 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning',
 * @returns {JSX.Element}
 * @constructor
 */
export const AlertDialog = ({ message, onOk, buttonColor, ...rest }) => {
  const classes = commonModalStyle();

  const _buttonColor = buttonColor || 'primary';

  return (
    <CommonDialog
      {...rest}
      onClose={onOk}
      className={classes.alertConfirm}
      options={{
        removeCancel: true,
        backdropClick: false,
        maxWidth: 'xs',
      }}
      actionButtons={[
        {
          name: '확인',
          callback: onOk,
          color: _buttonColor,
        },
      ]}>
      {/* 메시지 영역 - 스타일 적용됨 */}
      <DialogContentText>{message}</DialogContentText>
    </CommonDialog>
  );
};
