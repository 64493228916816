import React from 'react';
// react-router 버전이 v5 로 올라가면서, react-router-redux 를 대신하여 connected-react-router 를 이용함
// https://github.com/supasate/connected-react-router
// https://hokeydokey.tistory.com/74
//import { ConnectedRouter } from 'connected-react-router';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { history, store, persistor } from './redux/store';
import AppWrapper from './@jumbo/components/AppWrapper';
import AppContextProvider from './@jumbo/components/contextProvider/AppContextProvider';
import WmsRoutes from './routes';
import { PersistGate } from 'redux-persist/integration/react';

export { history, store };

const App = () => {
  return (
    <Provider store={store}>
      <BrowserRouter history={history}>
        <PersistGate loading={null} persistor={persistor}>
          <AppContextProvider>
            <AppWrapper>
              <WmsRoutes />
            </AppWrapper>
          </AppContextProvider>
        </PersistGate>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
