import React, { createContext, useState } from 'react';
import { AlertDialog } from 'common/Modals/AlertDialog';
import { v4 as uuid } from 'uuid';
import DialogType from 'enums/DialogType';
import { ConfirmDialog } from 'common/Modals/ConfirmDialog';

const defaultDialog = {
  // 사용자에게 입력 받는 영역
  message: null,
  buttonColor: null, // 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning',
  onOk: null,
  onCancel: null,
  // 다이얼로그 컨텍스트 내부 처리용 값
  key: null,
  type: null,
  _onOk: null,
  _onCancel: null,
  // 결과값
  result: null,
};

export const CommonDialogContext = createContext({});

export const CommonDialogProvider = ({ children }) => {
  const dialogManagerRef = React.useRef();

  /**
   * alert 다이얼로그 노출
   * @param message 메시지
   * @param {Object} options
   * @param {String} options.message 메시지
   * @param {String} options.buttonColor 버튼 색상 - 기본값 primary
   * @param {Function} options.onOk 확인 콜백 - 없을경우 호출 x
   * @returns {Promise} Promise 응답
   */
  const alert = (message, options) => {
    if (!message) return false;
    return dialogManagerRef.current.alert(Object.assign({}, defaultDialog, options, { message }));
  };

  /**
   * confirm 다이얼로그 노출
   * @param message 메시지
   * @param {Object} options
   * @param {String} options.message 메시지
   * @param {String} options.buttonColor 버튼 색상 - 기본값 primary
   * @param {Function} options.onOk 확인 콜백 - 없을경우 호출 x
   * @param {Function} options.onCancel 취소 콜백 - 없을경우 호출 x
   * @returns {Promise} Promise 응답
   */
  const confirm = (message, options) => {
    if (!message) return false;
    return dialogManagerRef.current.confirm(Object.assign({}, defaultDialog, options, { message }));
  };

  return (
    <CommonDialogContext.Provider value={{ alert, confirm }}>
      <DialogManager ref={dialogManagerRef} alert={alert} />
      {children}
    </CommonDialogContext.Provider>
  );
};

export default CommonDialogProvider;

// 다이얼로그 관리 - 부모에서 자식 method 호출
const DialogManager = React.forwardRef((props, ref) => {
  const [dialogs, setDialogs] = useState([]);
  const show = dialog => {
    return new Promise((resolve, reject) => {
      dialog.key = uuid();
      setDialogs([...dialogs, dialog]);

      // 확인 버튼 핸들러 - alert은 확인만
      dialog._onOk = (event, reason) => {
        // 다이얼로그 제거
        setDialogs(dialogs.filter(_dialog => _dialog.key !== dialog.key));

        // 틱이 겹쳐서 정상실행이 되지않아 타임아웃 추가
        setTimeout(() => {
          // 확인 콜백있으면 실행
          if (dialog.onOk && typeof dialog.onOk === 'function') dialog.onOk(dialog, event, reason);
        }, 100);

        dialog.result = true;
        resolve(dialog);
      };

      // 취소 버튼 핸들러
      dialog._onCancel = (event, reason) => {
        // 다이얼로그 제거
        setDialogs(dialogs.filter(_dialog => _dialog.key !== dialog.key));

        // 틱이 겹쳐서 정상실행이 되지않아 타임아웃 추가
        setTimeout(() => {
          // 취소 콜백있으면 실행
          if (dialog.onCancel && typeof dialog.onCancel === 'function') dialog.onCancel(dialog, event, reason);
        }, 100);

        dialog.result = false;
        resolve(dialog);
      };
    });
  };

  // alert 다이얼로그 노출
  const alert = dialog => {
    dialog.type = DialogType.ALERT;
    return show(dialog);
  };

  // confirm 다이얼로그 노출
  const confirm = dialog => {
    dialog.type = DialogType.CONFIRM;
    return show(dialog);
  };

  // 부모에게 함수 전달
  React.useImperativeHandle(ref, () => ({
    alert,
    confirm,
  }));

  return (
    <React.Fragment>
      {dialogs.length > 0 &&
        dialogs.map((dialog, index) => (
          <React.Fragment key={uuid()}>
            {dialog.type === DialogType.ALERT && (
              <AlertDialog
                key={uuid()}
                open={true}
                onOk={dialog._onOk}
                message={dialog.message}
                buttonColor={dialog.buttonColor}
              />
            )}

            {dialog.type === DialogType.CONFIRM && (
              <ConfirmDialog
                key={uuid()}
                open={true}
                onOk={dialog._onOk}
                onCancel={dialog._onCancel}
                message={dialog.message}
                buttonColor={dialog.buttonColor}
              />
            )}
          </React.Fragment>
        ))}
    </React.Fragment>
  );
});
