import { fetchError, fetchStart, fetchSuccess } from 'redux/actions';
import { setAuthUser, setForgetPassMailSent, updateLoadUser } from 'redux/actions/Auth';
import React from 'react';
import http from 'api/http';
import { pushHistory } from 'redux/actions/History';

const BasicAuth = {
  onRegister: ({ username, name, email, password, password_information }) => {
    return dispatch => {
      dispatch(fetchStart());

      http
        .post('/auth/register', {
          username: username,
          name: name,
          email: email,
          password: password,
          password_information: password_information,
        })
        .then(res => {
          if (res.status === 201) {
            dispatch(fetchSuccess());
            window.alert('회원가입 되었습니다.');
            dispatch(pushHistory('/signin'));
          }
        })
        .catch(error => {
          const messages = error.response.data.errors.full_messages;
          dispatch(fetchError(messages[0]));
        });
    };
  },

  onLogin: ({ email, password }) => {
    return dispatch => {
      try {
        dispatch(fetchStart());
        /**
         * TODO: 로그인 서버 통신
         */
        setTimeout(() => {
          const user = { name: 'Admin', email: email, password: password };
          dispatch(fetchSuccess());
          localStorage.setItem('user', JSON.stringify(user));
          dispatch(setAuthUser(user));
        }, 300);
      } catch (error) {
        window.alert(error.response?.data?.meta?.message);
      }
    };
  },
  onLogout: () => {
    return dispatch => {
      dispatch(fetchStart());

      setTimeout(() => {
        dispatch(fetchSuccess());
        localStorage.removeItem('user');
        dispatch(setAuthUser(null));
      }, 300);
    };
  },

  getAuthUser: (loaded = false) => {
    return dispatch => {
      dispatch(fetchStart());
      dispatch(updateLoadUser(loaded));

      setTimeout(() => {
        dispatch(fetchSuccess());
        dispatch(setAuthUser(JSON.parse(localStorage.getItem('user'))));
      }, 300);
    };
  },

  onForgotPassword: () => {
    return dispatch => {
      dispatch(fetchStart());

      setTimeout(() => {
        dispatch(setForgetPassMailSent(true));
        dispatch(fetchSuccess());
      }, 300);
    };
  },
  getSocialMediaIcons: () => {
    return <React.Fragment> </React.Fragment>;
  },
};

export default BasicAuth;
