import { SAVE_PRODUCT_HISTORY } from '@jumbo/constants/ActionTypes';

const INIT_STATE = {
  product_history: [],
  packing_table_move_history: [],
  wholesale_return_table_arrive_history: [],
  repair_table_arrive_history: [],
  seller_confirm_table_arrive_history: [],
  reset_table_move_history: [],
  after_wholesale_return_table_arrive_history: [],
  wholesale_return_table_arrive_many_history: [],
  direct_seller_confirm_table_arrive_history: [],
  seller_confirm_table_arrive_many_history: [],
  return_product_management_history: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SAVE_PRODUCT_HISTORY: {
      if (action.payload.type === 'packing_table_move') {
        if (state.packing_table_move_history?.length === 0) {
          return {
            ...state,
            packing_table_move_history: [action.payload.history],
          };
        } else if (state.packing_table_move_history?.length === 50) {
          state.packing_table_move_history.pop();
          return {
            ...state,
            packing_table_move_history: [action.payload.history, ...state.packing_table_move_history],
          };
        } else {
          return {
            ...state,
            packing_table_move_history: [action.payload.history, ...(state.packing_table_move_history || [])],
          };
        }
      } else if (action.payload.type === 'wholesale_return_table_arrive') {
        if (state.wholesale_return_table_arrive_history?.length === 0) {
          return {
            ...state,
            wholesale_return_table_arrive_history: [action.payload.history],
          };
        } else if (state.wholesale_return_table_arrive_history?.length === 50) {
          state.wholesale_return_table_arrive_history.pop();
          return {
            ...state,
            wholesale_return_table_arrive_history: [action.payload.history, ...state.wholesale_return_table_arrive_history],
          };
        } else {
          return {
            ...state,
            wholesale_return_table_arrive_history: [
              action.payload.history,
              ...(state.wholesale_return_table_arrive_history || []),
            ],
          };
        }
      } else if (action.payload.type === 'repair_table_arrive') {
        if (state.repair_table_arrive_history?.length === 0) {
          return {
            ...state,
            repair_table_arrive_history: [action.payload.history],
          };
        } else if (state.repair_table_arrive_history?.length === 50) {
          state.repair_table_arrive_history.pop();
          return {
            ...state,
            repair_table_arrive_history: [action.payload.history, ...state.repair_table_arrive_history],
          };
        } else {
          return {
            ...state,
            repair_table_arrive_history: [action.payload.history, ...(state.repair_table_arrive_history || [])],
          };
        }
      } else if (action.payload.type === 'seller_confirm_table_arrive') {
        if (state.seller_confirm_table_arrive_history?.length === 0) {
          return {
            seller_confirm_table_arrive_history: [action.payload.history],
          };
        } else if (state.seller_confirm_table_arrive_history?.length === 50) {
          state.seller_confirm_table_arrive_history.pop();
          return {
            ...state,
            seller_confirm_table_arrive_history: [action.payload.history, ...state.seller_confirm_table_arrive_history],
          };
        } else {
          return {
            ...state,
            seller_confirm_table_arrive_history: [
              action.payload.history,
              ...(state.seller_confirm_table_arrive_history || []),
            ],
          };
        }
      } else if (action.payload.type === 'reset_table_move_history') {
        if (state.reset_table_move_history?.length === 0) {
          return {
            reset_table_move_history: [action.payload.history],
          };
        } else if (state.reset_table_move_history?.length === 50) {
          state.reset_table_move_history.pop();
          return {
            ...state,
            reset_table_move_history: [action.payload.history, ...state.reset_table_move_history],
          };
        } else {
          return {
            ...state,
            reset_table_move_history: [action.payload.history, ...(state.reset_table_move_history || [])],
          };
        }
      } else if (action.payload.type === 'after_wholesale_return_table_arrive_history') {
        if (state.after_wholesale_return_table_arrive_history?.length === 0) {
          return {
            after_wholesale_return_table_arrive_history: [action.payload.history],
          };
        } else if (state.after_wholesale_return_table_arrive_history?.length === 50) {
          state.after_wholesale_return_table_arrive_history.pop();
          return {
            ...state,
            after_wholesale_return_table_arrive_history: [
              action.payload.history,
              ...state.after_wholesale_return_table_arrive_history,
            ],
          };
        } else {
          return {
            ...state,
            after_wholesale_return_table_arrive_history: [
              action.payload.history,
              ...(state.after_wholesale_return_table_arrive_history || []),
            ],
          };
        }
      } else if (action.payload.type === 'wholesale_return_table_arrive_many_history') {
        if (state.wholesale_return_table_arrive_many_history?.length === 0) {
          return {
            wholesale_return_table_arrive_many_history: [action.payload.history],
          };
        } else if (state.wholesale_return_table_arrive_many_history?.length === 50) {
          state.wholesale_return_table_arrive_many_history.pop();
          return {
            ...state,
            wholesale_return_table_arrive_many_history: [
              action.payload.history,
              ...state.wholesale_return_table_arrive_many_history,
            ],
          };
        } else {
          return {
            ...state,
            wholesale_return_table_arrive_many_history: [
              action.payload.history,
              ...(state.wholesale_return_table_arrive_many_history || []),
            ],
          };
        }
      } else if (action.payload.type === 'direct_seller_confirm_table_arrive') {
        if (state.direct_seller_confirm_table_arrive_history?.length === 0) {
          return {
            direct_seller_confirm_table_arrive_history: [action.payload.history],
          };
        } else if (state.direct_seller_confirm_table_arrive_history?.length === 50) {
          state.direct_seller_confirm_table_arrive_history.pop();
          return {
            ...state,
            direct_seller_confirm_table_arrive_history: [
              action.payload.history,
              ...state.direct_seller_confirm_table_arrive_history,
            ],
          };
        } else {
          return {
            ...state,
            direct_seller_confirm_table_arrive_history: [
              action.payload.history,
              ...(state.direct_seller_confirm_table_arrive_history || []),
            ],
          };
        }
      } else if (action.payload.type === 'seller_confirm_table_arrive_many_history') {
        if (state.seller_confirm_table_arrive_many_history?.length === 0) {
          return {
            seller_confirm_table_arrive_many_history: [action.payload.history],
          };
        } else if (state.seller_confirm_table_arrive_many_history?.length === 50) {
          state.seller_confirm_table_arrive_many_history.pop();
          return {
            ...state,
            seller_confirm_table_arrive_many_history: [
              action.payload.history,
              ...state.seller_confirm_table_arrive_many_history,
            ],
          };
        } else {
          return {
            ...state,
            seller_confirm_table_arrive_many_history: [
              action.payload.history,
              ...(state.seller_confirm_table_arrive_many_history || []),
            ],
          };
        }
      } else if (action.payload.type === 'return_product_management') {
        if (state.return_product_management_history?.length === 0) {
          return {
            return_product_management_history: [action.payload.history],
          };
        } else if (state.return_product_management_history?.length === 50) {
          state.return_product_management_history.pop();
          return {
            ...state,
            return_product_management_history: [action.payload.history, ...state.return_product_management_history],
          };
        } else {
          return {
            ...state,
            return_product_management_history: [action.payload.history, ...(state.return_product_management_history || [])],
          };
        }
      }

      break;
    }
    default:
      return state;
  }
};
