import React, { useContext, useEffect, useMemo } from 'react';
import { create } from 'jss';
import rtl from 'jss-rtl';

import { ToastContainerWrapper } from 'common/toast';
import { jssPreset } from '@mui/styles';
import { createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
// import { ko } from 'date-fns/locale';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import koLocale from 'date-fns/locale/ko';
import { StylesProvider } from '@mui/styles';
import CssBaseline from '@mui/material/CssBaseline';
import AppContext from '../contextProvider/AppContextProvider/AppContext';
import AppLayout from '../AppLayout';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { pushHistory } from 'redux/actions/History';
import ContentLoader from '@jumbo/components/ContentLoader';
import LoadingProvider from '@jumbo/components/contextProvider/LoadingContextProvider';
import CommonDialogContextProvider from '@jumbo/components/contextProvider/CommonDialogContextProvider';
import GlobalProgress from 'components/GlobalProgress';

// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const AppWrapper = ({ children }) => {
  const { theme } = useContext(AppContext);

  const push_history = useSelector(state => state.history.push_history);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    // 같은 url 이동을 위해 여부까지 추가검증
    if (push_history.url !== null && push_history.isMoved === false) {
      navigate(push_history.url, { replace: true });
      dispatch(pushHistory(push_history.url, true)); // 이동 완료 상태로 변경
    }
    // deps 에 navigate 를 넣게 되면 push_history 를 사용하지 않고 navigate 만 사용한 경우에도
    // 해당 콜백이 실행되어 기존 push_history.url 로 돌아가게 되기 때문에 deps 는 push_history.url 만 걸어주었습니다.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [push_history.url, push_history.isMoved]);

  const muiTheme = useMemo(() => {
    return createTheme({
      ...theme,
      direction: theme.direction === 'rtl' ? 'rtl' : 'ltr',
      transitions: {
        create: () => 'none',
      },
    });
  }, [theme]);

  return (
    <ThemeProvider theme={muiTheme}>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={koLocale}>
        <StylesProvider jss={jss}>
          <CommonDialogContextProvider>
            <LoadingProvider>
              <GlobalProgress />
              <CssBaseline />
              <AppLayout>{children}</AppLayout>
              <ToastContainerWrapper />
              <ContentLoader />
            </LoadingProvider>
          </CommonDialogContextProvider>
        </StylesProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default AppWrapper;
