import React from 'react';
import { Navigate, useRoutes } from 'react-router';
import loadable from '@loadable/component';

const PurchaseDashboard = loadable(() => import('./PurchaseDashboard'));
const OrderDashboard = loadable(() => import('./OrderDashboard'));
const NotFound = loadable(() => import('../Errors/404'));

const Mobile = () => {
  const routes = [
    { index: true, element: <Navigate to="purchase-dashboard" replace /> },
    { path: 'purchase-dashboard', element: <PurchaseDashboard /> },
    { path: 'order-dashboard', element: <OrderDashboard /> },
    { path: '*', element: <NotFound /> },
  ];
  return useRoutes(routes);
};

export default Mobile;
