import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Hidden, Toolbar } from '@mui/material';
import { alpha, darken } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { VerifiedUser } from '@mui/icons-material';

import Logo from '@jumbo/components/AppLayout/partials/Logo';
import SidebarToggleHandler from '@coremat/CmtLayouts/Horizontal/SidebarToggleHandler';
import UserDropDown from '@jumbo/components/AppLayout/partials/UserDropDown';
import CmtHorizontal from '@coremat/CmtNavigation/Horizontal';
import { minimalHorizontalMenus } from '@jumbo/components/AppLayout/partials/menus';
import HistoryContext from '../../partials/HistoryContext';
import ReduxLogOnOffForLocal from '@jumbo/components/AppLayout/HorizontalLayouts/HorizontalMinimal/components/ReduxLogOnOffForLocal';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    padding: 0,
    minHeight: '40px !important',
    // [theme.breakpoints.down('md')]: {
    //   paddingTop: 12,
    //   paddingBottom: 12,
    // },
    '& .Cmt-appIcon': {
      color: theme.palette.text.secondary,
      '&:hover, &:focus': {
        color: darken(theme.palette.text.secondary, 0.2),
      },
      [theme.breakpoints.down('xs')]: {
        padding: 7,
      },
    },
    '& .Cmt-horizontalNavMenu': {
      position: 'static',
      '& .Cmt-navMegaBtn, & > .Cmt-navCollapse > .Cmt-navCollapseBtn': {
        minHeight: 40,
      },
    },
  },
  langRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 5,
    paddingRight: 5,
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      paddingLeft: 15,
      paddingRight: 15,
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 2,
      zIndex: 1,
      height: 35,
      width: 1,
      backgroundColor: alpha(theme.palette.common.dark, 0.15),
    },
  },
  searchIcon: {
    [theme.breakpoints.down('xs')]: {
      padding: 9,
    },
  },
  envTag: {
    position: 'absolute',
    left: 112,
    top: 15,
    borderTopRightRadius: 2,
    borderBottomRightRadius: 2,
    // fontWeight: 'bold',
    color: 'white',
    fontSize: '0.9em',
    opacity: 0.8,
    [theme.breakpoints.down('md')]: {
      left: '148px !important',
    },
  },
}));

const MainHeader = () => {
  const nowEnv = process.env.REACT_APP_ENV;

  const classes = useStyles();
  const { authUser } = useSelector(({ auth }) => auth);

  if (!authUser) return null;

  return (
    <Toolbar className={classes.root}>
      {/* 상용에서 실수할까봐 추가한 태그 */}
      {nowEnv !== 'production' && <span className={classes.envTag}>{nowEnv === 'development' ? 'dev' : nowEnv}</span>}
      <SidebarToggleHandler edge="start" color="inherit" aria-label="menu" />
      <Logo color="white" mr={{ xs: 2, sm: 4, lg: 6, xl: 8 }} />
      <Hidden mdDown>
        <CmtHorizontal menuItems={minimalHorizontalMenus} />
      </Hidden>

      <Box display="flex" alignItems="center" ml="auto">
        {/* 로컬 전용 컴포넌트 (리덕스 로그 on / off 스위치) */}
        {nowEnv === 'local' && <ReduxLogOnOffForLocal nowEnv={nowEnv} />}
        <HistoryContext />
        <VerifiedUser color="secondary" />
        <Box mx={1} style={{ color: 'rgba(255, 255, 255, 0.74)' }}>
          {authUser.username} 님
        </Box>
        <UserDropDown />
      </Box>
    </Toolbar>
  );
};

export default MainHeader;
