import React from 'react';
import { Navigate, useRoutes } from 'react-router';
import loadable from '@loadable/component';

const StatusRoutes = loadable(() => import('./Status'));
const ProgressRoutes = loadable(() => import('./Progress'));
const ReceivingRoutes = loadable(() => import('./Receiving'));
const ConfirmedRoutes = loadable(() => import('./Confirmed'));
const MysteryInvoiceRoutes = loadable(() => import('./MysteryInvoice'));
const NotFound = loadable(() => import('routes/Errors/404'));

const RetailReturn = () => {
  const routes = [
    { index: true, element: <Navigate to="status" replace /> },
    { path: '/', element: <Navigate to="status" /> },
    { path: 'status/*', element: <StatusRoutes /> },
    { path: 'progress/*', element: <ProgressRoutes /> },
    { path: 'receiving/*', element: <ReceivingRoutes /> },
    { path: 'confirmed/*', element: <ConfirmedRoutes /> },
    { path: 'mystery-invoice/*', element: <MysteryInvoiceRoutes /> },
    { path: '*', element: <NotFound /> },
  ];

  return useRoutes(routes);
};

export default RetailReturn;
