import axios from 'axios';
import { getToken } from './GetToken';
import { SlackService } from 'services/common/slack';

const http = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  timeout: 1000000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  maxBodyLength: Infinity,
  maxContentLength: Infinity,
});

http.interceptors.request.use(
  config => {
    const token = getToken();

    config.headers = {
      Authorization: `Bearer ${token ? token : ''}`,
      ...config.headers,
    };

    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

http.interceptors.response.use(
  response => {
    return response;
  },
  // eslint-disable-next-line require-await
  async error => {
    let error_json = JSON.stringify(error);
    if (error.response.status === 401) {
      window.location.href = '/signin'; // Login 창으로 이동
    } else if (error.response.status === 406) {
      await SlackService.send('Not Acceptable', error_json);
      window.location.href = '/signin'; // Login 창으로 이동
    } else if (error.response.status === 403) {
      window.alert('권한이 없는 사용자입니다.');
    } else if (error.response.status === 500) {
      await SlackService.send('Internal Server Error', error_json);
      window.alert('서버 오류가 발생하였습니다.');
    } else if (error.response.status === 404) {
      window.alert('데이터를 찾을 수 없습니다.');
    } else if (error.response.status === 400 || error.response.status === 422 || error.response.status === 300) {
      // 출고처리 및 DAS 피킹 시 alert 메시지 이후에 경고음이 들려 바로 return (ref: WMSQA-1242)
      if (
        error.config.method === 'put' &&
        (error.config.url.includes('/out/outgoings') || error.config.url.includes('/out/pickings'))
      )
        return Promise.reject(error);
      if (error.response?.data?.meta?.message) window.alert(error.response.data?.meta?.message);
    }
    return Promise.reject(error);
  },
);

export default http;
