import { makeStyles } from '@mui/styles';

const AgGridStyle = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    flexBasis: '100%',
  },
  dialogRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // margin: '0 auto',
  },
  dialogForm: {
    width: '400px',
  },
  modalArea: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 320,
    background: 'white',
    // border: '2px solid #000',
    boxShadow: 24,
    // padding: 30,
    borderRadius: 6,
  },
  agGrid: {
    flex: 1,
    width: '100%',
    // paddingTop: '1rem',
    paddingTop: '0',
    '& .ag-row-islast': {
      backgroundColor: '#f9f6ad',
    },
  },
  halfGrid: {
    flex: 1,
    width: '50%',
    paddingTop: '1rem',
    height: '90vh',
  },
  gridBox: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: 0,
    // marginTop: '1rem',
    // padding: '1.5rem',
    marginTop: '0',
    padding: '5px',
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
  },
  button: {
    marginLeft: '0.25rem !important',
    '&:first-child': {
      marginLeft: 0,
    },
  },
  inline_gap_s: {
    marginLeft: '0.25rem !important',
    '&:first-child': {
      marginLeft: 0,
    },
  },
  leftButton: {
    float: 'left',
  },
  rightButton: {
    float: 'right',
  },
  centerButton: {
    justifyContent: 'center !important',
  },
  tabMenu: {
    marginLeft: '0.35rem',
    '&:first-child': {
      marginLeft: 0,
    },
  },
  dataSummary: {
    color: '#D02804',
  },
  dashboardBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  height: {
    height: '1000px',
  },
  height100: {
    height: '100%',
  },
  errorMassage: {
    color: 'red',
  },
  pagination: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    bottom: '5.1rem',
    right: '2rem',
  },
  inline_gap_s_far: {
    marginLeft: '6rem !important',
    '&:first-child': {
      marginLeft: 0,
    },
  },
}));

export default AgGridStyle;
