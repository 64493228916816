import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageLoader from '../PageComponents/PageLoader';
import { fetchReset } from 'redux/actions';
import { toast } from 'react-toastify';
import { LoadingContext } from '@jumbo/components/contextProvider/LoadingContextProvider';
import { coloredToast } from 'common/toast';
import { FETCH_ERROR, FETCH_SUCCESS } from '@jumbo/constants/ActionTypes';
import { LOADING_RESET_DELAY_TIME } from '@jumbo/constants/Loading';

// function SlideTransition(props) {
//   return <Slide {...props} direction="left" />;
// }

const ContentLoader = () => {
  // redux 를 이용한 로딩 -> 하위호환 및 redux를 이용한 로딩처리
  const dispatch = useDispatch();
  const { actionType, key, loading, loadingMessage, error, message, useAlert } = useSelector(({ common }) => common);

  // 컨텍스트를 이용한 로딩 -> 간단한 로딩처리
  const { isLoading } = useContext(LoadingContext);

  // 로딩중 toast 관리 배열
  const [toasts, setToasts] = useState([]);

  // 로딩 메시지 표시용
  useEffect(() => {
    // 메시지가 없으면 return
    if (!Boolean(loadingMessage)) return;

    // 키가 없으면 default
    const _key = key || 'default';

    // 기존 토스트 검색
    const foundToast = toasts.find(t => t.key === _key);

    if (foundToast) {
      // 동일한 메시지면 return
      if (foundToast.loadingMessage === loadingMessage) return;
      // 메시지만 변경되었다면 update
      else if (!!loadingMessage) toast.update(foundToast.id, { render: loadingMessage });
    } else {
      // 신규는 그대로 추가
      setToasts([...toasts, { key: _key, loadingMessage, id: toast.loading(loadingMessage) }]);
    }
  }, [key, loadingMessage, toasts]);

  // 로딩 종료시 성공, 에러 메시지 출력
  useEffect(() => {
    // alert 사용 에러일 경우
    if (useAlert && Boolean(error)) {
      alert(error);
    } else {
      // 로딩 메시지는 3초 (기본 토스트 : 2초)
      if (Boolean(error)) coloredToast.error(error, { duration: 3000 });
      if (Boolean(message)) toast.success(message, { duration: 3000 });
    }
  }, [actionType, dispatch, error, message, useAlert]);

  // 로딩 종료시점 리셋 처리
  useEffect(() => {
    // 로딩 종단점일때 0.05초후 로딩효과 리셋 - toast 사용시 없어도 되지만 이전 메시지 클리어 및 useEffect 반응성을 위해 추가
    if (actionType === FETCH_ERROR || actionType === FETCH_SUCCESS) {
      setTimeout(() => {
        dispatch(fetchReset());
      }, LOADING_RESET_DELAY_TIME); // 처리 직후 로딩시 로딩효과 제거되는 이슈가 좀 있어서 속도 조절
    }
  }, [actionType, dispatch]);

  // 로딩 종료시점 로딩 toast 정리
  useEffect(() => {
    // 오픈된 로딩 토스트가 없으면 리턴
    if (toasts.length === 0) return;

    // 로딩 종단점일때 3초후 로딩효과 리셋 - toast 사용시 없어도 되지만 이전 메시지 클리어 및 useEffect 반응성을 위해 추가
    if (actionType === FETCH_ERROR || actionType === FETCH_SUCCESS) {
      // 키값이 같은 로딩 메시지 제거 (기본 키값 = '')
      toasts.filter(t => t.key === key).forEach(t => toast.dismiss(t.id));
      // default 는 항상 제거
      toasts.filter(t => t.key === 'default').forEach(t => toast.dismiss(t.id));

      // 다른 키는 유지 - 크기가 변했을때만 작동
      const filteredToasts = toasts.filter(t => t.key !== key).filter(t => t.key !== 'default');
      if (toasts.length !== filteredToasts.length) setToasts(filteredToasts);
    }
  }, [actionType, key, toasts]);

  return (
    <React.Fragment>
      {/* Redux 로딩 효과용 */}
      {loading && <PageLoader />}
      {/* Context 로딩효과용 */}
      {isLoading && <PageLoader />}

      {/* 로딩 스낵바 toast로 대체됨 */}
      {/*{*/}
      {/*  <Snackbar*/}
      {/*    open={Boolean(error)}*/}
      {/*    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}*/}
      {/*    TransitionComponent={SlideTransition}>*/}
      {/*    <Alert variant="filled" severity="error">*/}
      {/*      {error}*/}
      {/*    </Alert>*/}
      {/*  </Snackbar>*/}
      {/*}*/}
      {/*{*/}
      {/*  <Snackbar*/}
      {/*    open={Boolean(message)}*/}
      {/*    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}*/}
      {/*    TransitionComponent={SlideTransition}>*/}
      {/*    <Alert variant="filled" severity="success">*/}
      {/*      {message}*/}
      {/*    </Alert>*/}
      {/*  </Snackbar>*/}
      {/*}*/}
    </React.Fragment>
  );
};

export default ContentLoader;
