import Enum from './Enum';

const DialogType = (() => {
  return new Enum({
    ALERT: {
      name: 'alert',
      type: 'alert',
    },
    CONFIRM: {
      name: 'confirm',
      type: 'confirm',
    },
  });
})();
export default DialogType;
