import React from 'react';

export const Menus = [
  // 입고 관리
  {
    name: <div>입고관리</div>,
    type: 'collapse',
    link: '/warehousing-management',
    children: [
      {
        name: <div>사입 입고</div>,
        type: 'collapse',
        link: '/warehousing-management/purchase',
        children: [
          {
            name: <div>입고 요청</div>,
            type: 'item',
            link: '/warehousing-management/purchase/receiving-request',
          },
          {
            name: <div>입고 대기</div>,
            type: 'item',
            link: '/warehousing-management/purchase/stand-by',
          },
          {
            name: <div>입고 검수진행</div>,
            type: 'item',
            link: '/warehousing-management/purchase/inspection-progress',
          },
          {
            name: <div>입고 검수진행(간소화)</div>,
            type: 'item',
            link: '/warehousing-management/purchase/inspection-progress-simple',
          },
          {
            name: <div>입고 확정 가능</div>,
            type: 'item',
            link: '/warehousing-management/purchase/confirm-possible',
          },
          {
            name: <div>입고 확정</div>,
            type: 'item',
            link: '/warehousing-management/purchase/fixed',
          },
          {
            name: <div>입고 진행현황</div>,
            type: 'item',
            link: '/warehousing-management/purchase/tracking-progress',
          },
          {
            name: <div>입고 상품 정보 상세</div>,
            type: 'item',
            link: '/warehousing-management/purchase/detail-info',
          },
          {
            name: <div>입고 상품 이동</div>,
            type: 'collapse',
            link: '/warehousing-management/purchase/product-move',
            children: [
              {
                name: <div>포장테이블이동</div>,
                type: 'item',
                link: '/warehousing-management/purchase/product-move/packing-table-move',
              },
              {
                name: <div>도매 반품테이블 도착</div>,
                type: 'item',
                link: '/warehousing-management/purchase/product-move/wholesale-return-table-arrive',
              },
              {
                name: <div>도매 반품테이블 도착(입고확정 후)</div>,
                type: 'item',
                link: '/warehousing-management/purchase/product-move/after-wholesale-return-table-arrive',
              },
              {
                name: <div>도매 반품테이블 도착(대량)</div>,
                type: 'item',
                link: '/warehousing-management/purchase/product-move/wholesale-return-table-arrive-many',
              },
              {
                name: <div>수선 테이블 도착</div>,
                type: 'item',
                link: '/warehousing-management/purchase/product-move/repair-table-arrive',
              },
              {
                name: <div>셀러확인 테이블 도착</div>,
                type: 'item',
                link: '/warehousing-management/purchase/product-move/seller-confirm-table-arrive',
              },
              {
                name: <div>셀러확인 테이블 도착(대량)</div>,
                type: 'item',
                link: '/warehousing-management/purchase/product-move/seller-confirm-table-arrive-many',
              },
              {
                name: <div>포장테이블상품</div>,
                type: 'item',
                link: '/warehousing-management/purchase/product-move/packing-table-product',
              },
              {
                name: <div>도매 반품 테이블 상품</div>,
                type: 'item',
                link: '/warehousing-management/purchase/product-move/wholesale-return-table-product',
              },
              {
                name: <div>수선 테이블 상품</div>,
                type: 'item',
                link: '/warehousing-management/purchase/product-move/repair-table-product',
              },
              {
                name: <div>셀러 확인 테이블 상품</div>,
                type: 'item',
                link: '/warehousing-management/purchase/product-move/seller-check-table-product',
              },
              {
                name: <div>테이블 이동 전으로 변경</div>,
                type: 'item',
                link: '/warehousing-management/purchase/product-move/reset-table-move',
              },
            ],
          },
          {
            name: <div>상품이동 과거이력</div>,
            type: 'collapse',
            link: '/warehousing-management/purchase/product-moving-history',
            children: [
              {
                name: <div>셀러 확인 테이블 상품(과거이력)</div>,
                type: 'item',
                link: '/warehousing-management/purchase/product-moving-history/seller-check-table-product',
              },
              {
                name: <div>도매처 반품 테이블 상품(과거이력)</div>,
                type: 'item',
                link: '/warehousing-management/purchase/product-moving-history/wholesale-return-table-product',
              },
            ],
          },
          {
            name: <div>즉시출고대상 입고확정가능</div>,
            type: 'item',
            link: '/warehousing-management/purchase/confirm-possible-nowout',
          },
        ],
      },
      {
        name: <div>직접 입고</div>,
        type: 'collapse',
        link: '/warehousing-management/self',
        children: [
          {
            name: <div>직접 입고 요청</div>,
            type: 'item',
            link: '/warehousing-management/self/warehousing-request',
          },
          {
            name: <div>직접 입고 센터도착</div>,
            type: 'item',
            link: '/warehousing-management/self/warehousing-standby',
          },
          {
            name: <div>직접 입고 검수</div>,
            type: 'item',
            link: '/warehousing-management/self/warehousing-inspection-confirmation',
          },
          {
            name: <div>직접 입고 (상품 단위)조회</div>,
            type: 'item',
            link: '/warehousing-management/self/per-product',
          },
          {
            name: <div>직접 입고 (회차 단위)조회</div>,
            type: 'item',
            link: '/warehousing-management/self/per-rotate',
          },
          {
            name: <div>입고 상품 이동</div>,
            type: 'collapse',
            link: '/warehousing-management/self/product-move',
            children: [
              {
                name: <div>셀러확인 테이블 도착</div>,
                type: 'item',
                link: '/warehousing-management/self/product-move/seller-confirm-table-arrive',
              },
              {
                name: <div>셀러확인 테이블 도착(대량)</div>,
                type: 'item',
                link: '/warehousing-management/self/product-move/seller-confirm-table-arrive-many',
              },
              {
                name: <div>셀러확인 테이블 상품</div>,
                type: 'item',
                link: '/warehousing-management/self/product-move/seller-confirm-table-product',
              },
              {
                name: <div>테이블 이동 전으로 변경</div>,
                type: 'item',
                link: '/warehousing-management/self/product-move/reset-table-move',
              },
            ],
          },
          {
            name: <div>상품 이동 과거이력</div>,
            type: 'collapse',
            link: '/warehousing-management/self/product-moving-history',
            children: [
              {
                name: <div>셀러확인 테이블 상품(과거이력)</div>,
                type: 'item',
                link: '/warehousing-management/self/product-moving-history/seller-confirm-table-arrive-history',
              },
            ],
          },
          {
            name: <div>상품 상세</div>,
            type: 'item',
            link: '/warehousing-management/self/product-detail',
          },
        ],
      },
    ],
  },

  // 출고 관리
  {
    name: <div>출고 관리</div>,
    type: 'collapse',
    link: '/shipment-management',
    children: [
      {
        name: <div>출고 현황 조회</div>,
        type: 'item',
        link: '/shipment-management/status-check',
      },
      {
        name: <div>출고 대상 리스트 조회</div>,
        type: 'collapse',
        link: '/shipment-management/target-check',
        children: [
          {
            name: <div>출고 대기</div>,
            type: 'item',
            link: '/shipment-management/target-check/standby',
          },
          {
            name: <div>출고 등록 실패</div>,
            type: 'item',
            link: '/shipment-management/target-check/failure',
          },
          {
            name: <div>출고 보류</div>,
            type: 'item',
            link: '/shipment-management/target-check/hold',
          },
          {
            name: <div>출고완료 취소요청</div>,
            type: 'item',
            link: '/shipment-management/target-check/cancel_complete',
          },
        ],
      },
      {
        name: <div>출고 회차별 조회</div>,
        type: 'item',
        link: '/shipment-management/rotate-check',
      },
      {
        name: <div>출고 회차 주문별 조회</div>,
        type: 'item',
        link: '/shipment-management/order-check',
      },
      {
        name: <div>출고 회차 상품별 조회</div>,
        type: 'item',
        link: '/shipment-management/waves/products',
      },
      {
        name: <div>DAS 피킹</div>,
        type: 'item',
        link: '/shipment-management/das-picking',
      },
      {
        name: <div>출고 처리</div>,
        type: 'collapse',
        link: '/shipment-management/shipped',
        children: [
          {
            name: <div>DAS 출고 처리</div>,
            type: 'item',
            link: '/shipment-management/shipped/das',
          },
          {
            name: <div>바로 출고 처리</div>,
            type: 'item',
            link: '/shipment-management/shipped/direct',
          },
          {
            name: <div>오토백 출고 처리</div>,
            type: 'item',
            link: '/shipment-management/shipped/autobag',
          },
          {
            name: <div>즉시 출고 처리</div>,
            type: 'item',
            link: '/shipment-management/shipped/now-out',
          },
          {
            name: <div>개별 출고 처리</div>,
            type: 'item',
            link: '/shipment-management/shipped/orders',
          },
        ],
      },
      {
        name: <div>출고처리 결과조회</div>,
        type: 'item',
        link: '/shipment-management/task-history',
      },
      {
        name: <div>부족재고 선주문 등록리스트 조회</div>,
        type: 'item',
        link: '/shipment-management/first-order',
      },
      {
        name: <div>즉시출고대상 상품리스트 조회</div>,
        type: 'item',
        link: '/shipment-management/now-out-order',
      },
    ],
  },

  // 반품 관리 (도매처)
  {
    name: <div>반품 관리 (도매처)</div>,
    type: 'collapse',
    link: '/wholesale-return',
    children: [
      {
        name: <div>도매 반품 접수</div>,
        type: 'item',
        link: '/wholesale-return/receipt',
      },
      {
        name: <div>도매 반품 현황</div>,
        type: 'item',
        link: '/wholesale-return/status',
      },
    ],
  },

  // 회수 관리 (쇼핑몰)
  {
    name: <div>회수 관리 (쇼핑몰)</div>,
    type: 'collapse',
    link: '/retail-return',
    children: [
      {
        name: <div>회수 현황 조회</div>,
        type: 'collapse',
        link: '/retail-return/status',
        children: [
          {
            name: <div>회수대상목록</div>,
            type: 'item',
            link: '/retail-return/status/check',
          },
          {
            name: <div>회수대상 상품목록</div>,
            type: 'item',
            link: '/retail-return/status/product',
          },
        ],
      },
      {
        name: <div>회수 진행 관리</div>,
        type: 'collapse',
        link: '/retail-return/progress',
        children: [
          {
            name: <div>회수접수 완료</div>,
            type: 'item',
            link: '/retail-return/progress/done',
          },
          {
            name: <div>회수 진행</div>,
            type: 'item',
            link: '/retail-return/progress/accepted',
          },
        ],
      },
      {
        name: <div>회수 입고 관리</div>,
        type: 'collapse',
        link: '/retail-return/receiving',
        children: [
          {
            name: <div>회수 입고 검수</div>,
            type: 'item',
            link: '/retail-return/receiving/inspection',
          },
        ],
      },
      {
        name: <div>회수 확정 관리</div>,
        type: 'collapse',
        link: '/retail-return/confirmed',
        children: [
          {
            name: <div>회수상품 확정처리</div>,
            type: 'item',
            link: '/retail-return/confirmed/progress',
          },
          {
            name: <div>회수상품 확정처리 상세조회</div>,
            type: 'item',
            link: '/retail-return/confirmed/refund-product-management',
          },
          {
            name: <div>환불대상 상품관리</div>,
            type: 'item',
            link: '/retail-return/confirmed/refund',
          },
        ],
      },
      {
        name: <div>미확인 반송장관리</div>,
        type: 'item',
        link: '/retail-return/mystery-invoice/list',
      },
      // {
      //   name: <div>셀러 문의 상품 관리</div>,
      //   type: 'item',
      //   link: '/retail-return/seller-contact',
      // },
      // {
      //   name: <div>반품 접수 관리-논의필</div>,
      //   type: 'item',
      //   link: '/retail-return/return-accepted',
      // },
      // {
      //   name: <div>반품 입고 처리-논의필</div>,
      //   type: 'item',
      //   link: '/retail-return/return-warehouse',
      // },
    ],
  },

  // 상품 관리
  {
    name: <div>상품 관리</div>,
    type: 'collapse',
    link: '/product',
    children: [
      {
        name: <div>상품정보관리</div>,
        type: 'item',
        link: '/product/inventory-check',
      },
      {
        name: <div>셀러정보관리</div>,
        type: 'item',
        link: '/product/seller-check',
      },
      {
        name: <div>상품로케이션 일괄이동</div>,
        type: 'item',
        link: '/product/moving-location-all',
      },
      {
        name: <div>상품로케이션 선택이동</div>,
        type: 'item',
        link: '/product/moving-location',
      },
      {
        name: <div>로케이션 이동내역조회</div>,
        type: 'item',
        link: '/product/moving-history',
      },
      {
        name: <div>상품재고 차감요청조회</div>,
        type: 'item',
        link: '/product/request-decrease',
      },
      {
        name: <div>상품재고 증가요청조회</div>,
        type: 'item',
        link: '/product/request-increase',
      },
    ],
  },

  // 재고 관리
  {
    name: <div>재고 관리</div>,
    type: 'collapse',
    link: '/inventory',
    children: [
      {
        name: <div>현재고조회</div>,
        type: 'item',
        // TODO:현재고조회 api 개발 후 대치
        link: '/inventory/locations',
      },
      {
        name: <div>상품재고조회</div>,
        type: 'item',
        link: '/inventory/due-diligence',
      },
      {
        name: <div>상품 입출고 내역</div>,
        type: 'item',
        link: '/inventory/history-inout',
      },
      {
        name: <div>시리얼넘버 처리내역조회</div>,
        type: 'item',
        link: '/inventory/history-sn',
      },
      {
        name: <div>일마감 재고조회</div>,
        type: 'collapse',
        link: '/inventory/day-close',
        children: [
          {
            name: <div>입출고내역</div>,
            type: 'item',
            link: '/inventory/day-close/sku-stocks-history',
          },
          {
            name: <div>사입입고내역</div>,
            type: 'item',
            link: '/inventory/day-close/purchase-statistics-history',
          },
        ],
      },
      {
        name: <div>딜리버드재고 처리내역조회</div>,
        type: 'item',
        link: '/inventory/dealibird-skus',
      },
    ],
  },

  // 설정 관리
  {
    name: <div>설정 관리</div>,
    type: 'collapse',
    link: '/setting',
    children: [
      {
        name: <div>입고</div>,
        type: 'collapse',
        link: '/setting/warehousing',
        children: [
          {
            name: <div>포장 테이블 관리</div>,
            type: 'item',
            link: '/setting/warehousing/packing-table-management',
          },
        ],
      },
      {
        name: <div>출고</div>,
        type: 'collapse',
        link: '/setting/shipment',
        children: [
          {
            name: <div>출고 테이블 관리</div>,
            type: 'item',
            link: '/setting/shipment/shipment-table-management',
          },
        ],
      },
      {
        name: <div>로케이션</div>,
        type: 'collapse',
        link: '/setting/locations',
        children: [
          {
            name: <div>로케이션 관리</div>,
            type: 'item',
            link: '/setting/locations/management',
          },
        ],
      },
      {
        name: <div>사용자</div>,
        type: 'collapse',
        link: '/setting/user',
        children: [
          {
            name: <div>계정 관리</div>,
            type: 'item',
            link: '/setting/user/account',
          },
        ],
      },
      {
        name: <div>바코드</div>,
        type: 'collapse',
        link: '/setting/barcodes',
        children: [
          {
            name: <div>시스템 바코드 출력</div>,
            type: 'item',
            link: '/setting/barcodes/barcode-collection',
          },
          {
            name: <div>바코드 출력설정</div>,
            type: 'item',
            link: '/setting/barcodes/barcode-setting',
          },
        ],
      },
    ],
  },
];

if (process.env.REACT_APP_ENV === 'local') {
  Menus.push({
    name: <div>Demo</div>,
    type: 'collapse',
    link: '/demo',
    children: [
      {
        name: <div>List - AgGrid</div>,
        type: 'item',
        link: '/demo/list',
      },
      {
        name: <div>components</div>,
        type: 'item',
        link: '/demo/components',
      },
    ],
  });
}

export const MobileMenus = [
  {
    name: <div>입고 대쉬보드</div>,
    type: 'item',
    link: '/m/purchase-dashboard',
  },
  {
    name: <div>출고 대쉬보드</div>,
    type: 'item',
    link: '/m/order-dashboard',
  },
  ...Menus,
];

export const sidebarNavs = MobileMenus;

export const horizontalDefaultNavs = Menus;

export const minimalHorizontalMenus = Menus;

export const whoAmI = function(pathname, children, parents) {
  if (!parents) parents = [];
  const menus = !!children ? children : MobileMenus;

  for (const menu of menus) {
    if (menu.link === pathname) {
      // 찾았으면 부모와 함께 리턴
      return { menu, parents };
    } else if (!!menu.children) {
      // 경로가 일치하지 않고 자식이 있으면 자신을 부모에 넣고 더 깊게 찾기
      parents.push(menu);
      const found = whoAmI(pathname, menu.children, parents); // 자손이니까 push

      if (!!found) return { menu: found.menu, parents: found.parents };
    }
  }

  return false;
};

export const findMyChildren = function(pathname, children) {
  const menus = !!children ? children : MobileMenus;

  for (const menu of menus) {
    if (!!menu.children) {
      if (menu.link === pathname) {
        // 자식 숨김처리 안되있을때 리턴 - 230118-WMSQA-1223 모두 노출 요청 - 자식 메뉴 숨기고 싶을 경우 부모에 hide_children: true 옵션 추가
        if (!menu.hide_children) return menu.children;
        else return false;
      } else {
        // 경로가 일치하지 않고 자식이 있으면 더 깊게 찾기
        const found_children = findMyChildren(pathname, menu.children);

        if (!!found_children) return found_children;
      }
    }
  }

  return false;
};
