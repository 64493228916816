import React from 'react';
import { Navigate, useRoutes } from 'react-router';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import loadable from '@loadable/component';

const StatusCheck = loadable(() => import('./StatusCheck'));
const TargetCheckRoutes = loadable(() => import('./TargetCheck'));
const RotateCheck = loadable(() => import('./RotateCheck'));
const RotateCheckDetail = loadable(() => import('./RotateCheck/RotateCheckDetail'));
const OrderCheck = loadable(() => import('./OrderCheck'));
const Products = loadable(() => import('./Waves/Products'));
const DasPicking = loadable(() => import('./DasPicking'));
const ShippedRoutes = loadable(() => import('./Shipped'));
const TaskHistory = loadable(() => import('./TaskHistory'));
const NowOutOrder = loadable(() => import('./NowOutOrder'));
const PreOrder = loadable(() => import('./PreOrder'));
const NotFound = loadable(() => import('routes/Errors/404'));

const routes = [
  { index: true, element: <Navigate to="status-check" replace /> },
  { path: 'status-check', element: <StatusCheck /> },
  { path: 'target-check/*', element: <TargetCheckRoutes /> },
  { path: 'rotate-check', element: <RotateCheck /> },
  { path: 'rotate-check/:id', element: <RotateCheckDetail /> },
  { path: 'order-check', element: <OrderCheck /> },
  { path: 'waves/products', element: <Products /> },
  { path: 'das-picking', element: <DasPicking /> },
  { path: 'shipped/*', element: <ShippedRoutes /> },
  { path: 'task-history', element: <TaskHistory /> },
  { path: 'now-out-order', element: <NowOutOrder /> },
  { path: 'first-order', element: <PreOrder /> },
  { path: '*', element: <NotFound /> },
];

const Shipment = () => {
  return <PageContainer>{useRoutes(routes)}</PageContainer>;
};

export default Shipment;
