import React from 'react';
import { Navigate, useRoutes } from 'react-router';
import loadable from '@loadable/component';

const ListDemo = loadable(() => import('./ListDemo'));
const ComponentsDemo = loadable(() => import('./ComponentsDemo'));
const NotFound = loadable(() => import('../Errors/404'));

const WareHousing = () => {
  const routes = [
    { index: true, element: <Navigate to="list" replace /> },
    { path: 'list', element: <ListDemo /> },
    { path: 'components', element: <ComponentsDemo /> },
    { path: '*', element: <NotFound /> },
  ];
  return useRoutes(routes);
};

export default WareHousing;
