import {
  SET_STATUS_CHECK, // 출고현황조회
  SET_STATUS_CHECK_PAGINATION, // 출고현황조회
  SET_STATUS_CHECK_FILTER, // 출고현황조회
  SET_TARGET_STANDBY_CHECK, // 출고 대상 리스트 조회 - 대기
  SET_TARGET_FAILURE_CHECK, // 출고 대상 리스트 조회 - 출고등록실패
  SET_TARGET_HOLD_CHECK, // 출고 대상 리스트 조회 - 출고보류
  SET_CANCEL_COMPLETE, // 출고 대상 리스트 조회 - 출고완료 취소요청
  SET_CANCEL_COMPLETE_DETAIL, // 출고 대상 리스트 조회 - 출고완료 취소요청 - 배송요청번호, 송장번호 모달
  SET_ROTATE_CHECK, // 출고 회차별 리스트 조회
  SET_ROTATE_CHECK_DETAIL, // 출고 회차별 상세 조회
  SET_WAVE_COUNT_INFO, // 출고 회차별 상세 조회
  SET_ORDER_CHECK, // 출고회차 주문별 조회
  SET_INVOICE_ORDER, // 송장우선순위
  SET_PRINT_PICKING_LIST, // 출력용 피킹리스트

  // 출고관리 > DAS 피킹
  INIT_DAS_PICKING, // DAS 피킹 전역 상태 초기화
  SET_DAS_PICKING, // DAS 피킹 바코드 스캔시 상태 셋팅
  SET_DAS_PICKING_STATE, // DAS 피킹 진행 상태 변경
  SET_DAS_PICKING_HISTORY, // DAS 피킹 스캔이력
  CHANGE_PENDING_STATUS, // DAS 피킹 출고 보류 상태 변경
  ADD_SELECTED_ORDER, // DAS 피킹 - 현재 선택된 셀 추가
  REMOVE_SELECTED_ORDER, // DAS 피킹 - 현재 선택된 셀 제거
  TOGGLE_SELECTED_ORDER, // DAS 피킹 셀 선택 토글
  SET_TARGET_ORDER, // DAS 피킹 - 적재 대상 셀 세팅

  // 출고관리 > 출고 처리
  SET_SHIPPED_DESK, // 나의 작업 테이블 정보
  INIT_SHIPPED_DAS, // DAS 출고 상태 초기화
  SET_SHIPPED_DAS_PICKING, // DAS 출고 피킹지 바코드
  INIT_SHIPPED_DAS_INVOICE, // DAS 출고 송장 정보 초기화
  SET_SHIPPED_DAS_INVOICE, // DAS 출고 송장 바코드
  SET_SHIPPED_DIRECT_PICKING, // 바로 출고 피킹지 바코드
  SET_SHIPPED_DIRECT_PRODUCT, // 바로 출고 상품 바코드
  SET_SHIPPED_DIRECT_INVOICE, // 바로 출고 송장 바코드
  SET_AUTUBAG_PRODUCT_LIST,

  // 개별 출고
  INIT_SHIPPED_ORDERS, // 개별 출고 송장 바코드 초기화
  SET_SHIPPED_ORDERS, // 개별 출고 송장 바코드 셋팅
  INIT_SHIPPED_DIRECT,
  INIT_SHIPPED_DIRECT_PRODUCT,
  RESET_PRINT_PICKING_LIST,
  SET_PRODUCT_CODES_LIST,
  SET_SHIPPED_DIRECT_PRODUCT_CODE_LIST,
  SET_SHIPPED_ORDERS_PRODUCT_CODE_LIST,
  SET_AUTOBAG_PICKING_WAVE,
  SET_AUTOBAG_PRODUCT_COMPLETED,
  SET_AUTOBAG_PRODUCT_INCOMPLETED,

  // 즉시출고
  SET_NOT_RESULT,
  SET_FORM_DATE_RANGE_NOW_OUT,
  SET_NOT_REGISTER,
  SET_WAVE_PER_PRODUCT,
} from '@jumbo/constants/ActionTypes';

const INIT_STATE = {
  status_check_list: [],
  status_check_list_pagination: null,
  status_check_list_filter: null,
  target_standby_list: [],
  target_failure_list: [],
  target_hold_list: [],
  rotate_check_list: [],
  rotate_check_detail: [],
  wave_count_info: {},
  order_check_list: [],
  invoice_order: {},
  print_picking_list: [],
  // 출고 관리 > DAS 피킹
  das_picking: {
    picking_state: 'IDLE', // DAS 피킹 상태 (IDLE | WORKING | COMPLETED)
    selected_order: null, // 선택된 셀
    target_order: null, // 적치 대상 셀
    // 상단 피킹 리스트 정보
    picking_wave: {
      id: null,
      created_at: null,
      out_desk_id: null,
      orders_count: null,
      out_stock_count: null,
      picked_stock_count: null,
      canceled_stock_count: null,
    }, // 출고 회차 정보
    picking_orders: [], // 배송요청 목록, DAS 셀 정보 (picking_orders, picking_racks 병합)
    picking_history: [],
  },
  das_picking_product_codes_list: [], //DAS피킹에서 상품바코드 스캔 시 상품 코드 모달
  // 출고관리 > 출고 처리
  shipped_desk: [],
  shipped_das: {
    // DAS 출고 피킹지 바코드
    picking_wave: null,
    // DAS 출고 송장 바코드
    order_info: null,
    task_info: [],
  },
  // 바로출고
  shipped_direct: {
    picking_wave: null,
    order_info: null,
    task_info: null,
  },
  //오토백 출고
  shipped_autobag_picking_wave: null,
  shipped_autobag_product_list: [],
  shipped_autobag_product_list_completed: [],
  shipped_autobag_product_list_incompleted: [],
  shipped_direct_product_code_list: [], //바로 출고 에서 상품바코드 스캔 시 상품 코드 모달
  shipped_orders: {
    picking_wave: null, // 개별 출고 송장 바코드
    outgoing_tasks: [], // 개별 출고 상품 목록
  },
  shipped_orders_product_code_list: [], //개별 출고 에서 상품바코드 스캔 시 상품 코드 모달
  // 즉시출고
  not_result: false, // 즉시출고대상상품리스트조회 > 미처리 상품리스트만 보기
  form_date_range_now_out: null, // 즉시출고대상상품리스트조회 > 기간
  not_register: false, // 즉시출고대상상품리스트조회 > 출고미등록 상품리스트만 보기
  cancel_completed_list: [], // 출고완료 취소요청
  wave_per_product: [],
};

export default (state = INIT_STATE, action, dispatch) => {
  switch (action.type) {
    case SET_STATUS_CHECK: {
      return {
        ...state,
        status_check_list: action.payload,
      };
    }
    case SET_STATUS_CHECK_PAGINATION: {
      return {
        ...state,
        status_check_list_pagination: action.payload,
      };
    }
    case SET_STATUS_CHECK_FILTER: {
      return {
        ...state,
        status_check_list_filter: action.payload,
      };
    }
    case SET_TARGET_STANDBY_CHECK: {
      return {
        ...state,
        target_standby_list: action.payload,
      };
    }
    case SET_TARGET_FAILURE_CHECK: {
      return {
        ...state,
        target_failure_list: action.payload,
      };
    }
    case SET_TARGET_HOLD_CHECK: {
      return {
        ...state,
        target_hold_list: action.payload,
      };
    }
    case SET_CANCEL_COMPLETE: {
      return {
        ...state,
        cancel_completed_list: action.payload,
      };
    }
    case SET_CANCEL_COMPLETE_DETAIL: {
      return {
        ...state,
        cancel_completed_detail: action.payload,
      };
    }
    case SET_ROTATE_CHECK: {
      return {
        ...state,
        rotate_check_list: action.payload,
      };
    }
    case SET_ROTATE_CHECK_DETAIL: {
      return {
        ...state,
        rotate_check_detail: action.payload,
      };
    }
    case SET_WAVE_COUNT_INFO: {
      return {
        ...state,
        wave_count_info: action.payload,
      };
    }
    case SET_ORDER_CHECK: {
      return {
        ...state,
        order_check_list: action.payload,
        order_check_list_params: action.params,
      };
    }

    /**
     * 출고 관리 > DAS 피킹
     */
    case INIT_DAS_PICKING: {
      return {
        ...state,
        das_picking: {
          ...INIT_STATE.das_picking,
        },
      };
    }
    case SET_DAS_PICKING_STATE: {
      return {
        ...state,
        das_picking: {
          ...state.das_picking,
          picking_state: action.payload,
        },
      };
    }
    case SET_DAS_PICKING: {
      const { picking_wave, picking_orders, last_order_picking_cnt } = action.payload;

      return {
        ...state,
        das_picking: {
          ...state.das_picking,
          picking_wave,
          picking_orders,
          last_order_picking_cnt: last_order_picking_cnt,
        },
      };
    }
    case ADD_SELECTED_ORDER: {
      const _selected_rack = state.das_picking.selected_rack;
      const isExist = _selected_rack.find(rack => rack.das_cell_no === action.payload.das_cell_no);
      if (isExist) return state;

      _selected_rack.push(action.payload);

      return {
        ...state,
        das_picking: {
          ...state.das_picking,
          selected_rack: _selected_rack,
        },
      };
    }
    case REMOVE_SELECTED_ORDER: {
      const _selected_rack = state.das_picking.selected_rack.filter(rack => rack.das_cell_no !== action.payload.das_cell_no);

      return {
        ...state,
        das_picking: {
          ...state.das_picking,
          selected_rack: _selected_rack,
        },
      };
    }
    case TOGGLE_SELECTED_ORDER: {
      const selected_order =
        state.das_picking.selected_order?.das_cell_no === action.payload?.das_cell_no ? null : action.payload;

      return {
        ...state,
        das_picking: {
          ...state.das_picking,
          selected_order,
        },
      };
    }
    case SET_DAS_PICKING_HISTORY: {
      return {
        ...state,
        das_picking: {
          ...state.das_picking,
          picking_history: action.payload,
        },
      };
    }
    case SET_TARGET_ORDER: {
      return {
        ...state,
        das_picking: {
          ...state.das_picking,
          target_order: action.payload,
        },
      };
    }
    case CHANGE_PENDING_STATUS: {
      if (!state.das_picking.selected_order) return state;

      const selected_order_id = state.das_picking.selected_order.order_id;
      state.das_picking.picking_orders.forEach(order => {
        if (selected_order_id === order.order_id) {
          order.task_status = action.payload ? 'canceled' : 'working';
        }
      });

      return {
        ...state,
      };
    }
    case SET_PRODUCT_CODES_LIST: {
      return {
        ...state,
        das_picking_product_codes_list: action.payload,
      };
    }

    /**
     * 출고 관리 > 출고 처리
     */

    // DAS 출고 상태 초기화
    case INIT_SHIPPED_DAS: {
      return {
        ...state,
        shipped_das: {
          ...INIT_STATE.shipped_das,
        },
        shipped_desk: [],
      };
    }
    // DAS 출고 피킹지 바코드 세팅
    case SET_SHIPPED_DAS_PICKING: {
      return {
        ...state,
        shipped_das: {
          ...state.shipped_das,
          picking_wave: action.payload,
        },
      };
    }
    // DAS 출고 송장 바코드 초기화
    case INIT_SHIPPED_DAS_INVOICE: {
      return {
        ...state,
        shipped_das: {
          ...state.shipped_das,
          order_info: null,
          task_info: [],
        },
      };
    }
    // DAS 출고 송장 바코드 세팅
    case SET_SHIPPED_DAS_INVOICE: {
      return {
        ...state,
        shipped_das: {
          ...action.payload,
        },
      };
    }

    // 바로 출고 초기화
    case INIT_SHIPPED_DIRECT: {
      return {
        ...state,
        shipped_direct: { ...INIT_STATE.shipped_direct },
      };
    }
    // 바로 출고 피킹지 바코드
    case SET_SHIPPED_DIRECT_PICKING: {
      const { picking_wave, order_info } = action.payload;
      return {
        ...state,
        shipped_direct: {
          ...state.shipped_direct,
          picking_wave,
          order_info,
        },
      };
    }
    // 바로 출고 상품 바코드 초기화
    case INIT_SHIPPED_DIRECT_PRODUCT: {
      return {
        ...state,
        shipped_direct: { ...state.shipped_direct, task_info: null },
      };
    }
    // 바로 출고 상품 바코드
    case SET_SHIPPED_DIRECT_PRODUCT: {
      return {
        ...state,
        shipped_direct: {
          ...state.shipped_direct,
          task_info: action.payload,
        },
      };
    }
    case SET_SHIPPED_DIRECT_PRODUCT_CODE_LIST: {
      return {
        ...state,
        shipped_direct_product_code_list: action.payload,
      };
    }
    // 바로 출고 송장 바코드
    case SET_SHIPPED_DIRECT_INVOICE: {
      return {
        ...state,
        shipped_direct: {
          ...state.shipped_direct,
          picking_wave: action.payload,
        },
      };
    }

    //오토백 출고 > 피킹지 바코드 스캔 시 불러오는 상품 리스트
    case SET_AUTOBAG_PICKING_WAVE: {
      return {
        ...state,
        shipped_autobag_picking_wave: action.payload,
      };
    }
    case SET_AUTUBAG_PRODUCT_LIST: {
      return {
        ...state,
        shipped_autobag_product_list: action.payload,
      };
    }
    case SET_AUTOBAG_PRODUCT_COMPLETED: {
      return {
        ...state,
        shipped_autobag_product_list_completed: action.payload,
      };
    }

    case SET_AUTOBAG_PRODUCT_INCOMPLETED: {
      return {
        ...state,
        shipped_autobag_product_list_incompleted: action.payload,
      };
    }

    // 개별 출고 송장 바코드 초기화
    case INIT_SHIPPED_ORDERS: {
      return {
        ...state,
        shipped_orders: {
          ...INIT_STATE.shipped_orders,
          outgoing_tasks: [],
        },
      };
    }
    // 개별 출고 송장 바코드
    case SET_SHIPPED_ORDERS: {
      const { picking_wave, outgoing_tasks } = action.payload;

      return {
        ...state,
        shipped_orders: {
          picking_wave,
          outgoing_tasks,
        },
      };
    }
    case SET_SHIPPED_ORDERS_PRODUCT_CODE_LIST: {
      return {
        ...state,
        shipped_orders_product_code_list: action.payload,
      };
    }
    // 나의 작업 테이블
    case SET_SHIPPED_DESK: {
      return {
        ...state,
        shipped_desk: action.payload,
      };
    }
    case SET_INVOICE_ORDER: {
      return {
        ...state,
        invoice_order: action.payload,
      };
    }
    case SET_PRINT_PICKING_LIST: {
      return {
        ...state,
        print_picking_list: action.payload,
      };
    }
    case RESET_PRINT_PICKING_LIST: {
      return {
        ...state,
        print_picking_list: [],
      };
    }
    case SET_NOT_RESULT: {
      return {
        ...state,
        not_result: action.payload,
      };
    }
    case SET_FORM_DATE_RANGE_NOW_OUT: {
      return {
        ...state,
        form_date_range_now_out: action.payload,
      };
    }
    case SET_NOT_REGISTER: {
      return {
        ...state,
        not_register: action.payload,
      };
    }
    case SET_WAVE_PER_PRODUCT: {
      return {
        ...state,
        wave_per_product: action.payload,
        wave_per_product_params: action.params,
      };
    }
    default:
      return state;
  }
};
