import React, { cloneElement, isValidElement, useEffect, useMemo } from 'react';
import { Box, List, ListItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import NavMenuItem from './NavMenuItem';
import useStyles from './NavCollapse.style';
import clsx from 'clsx';
import { useLocation } from 'react-router';

const NavCollapse = props => {
  const navigate = useNavigate();
  const classes = useStyles();
  const location = useLocation();

  const { name, icon, className, children = [], link } = props;
  const isExpandable = useMemo(() => children.length, [children]);
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (location.pathname.includes(link + '/')) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [location, link]);

  const renderIcon = () => {
    if (icon && isValidElement(icon)) {
      return cloneElement(icon, {
        className: clsx(classes.iconRoot, 'Cmt-icon-root'),
      });
    }

    return null;
  };

  const MenuItemChildren = isExpandable ? (
    <List component="div" disablePadding className={classes.navCollapseItem}>
      {children.map((item, index) => {
        switch (item.type) {
          case 'collapse':
            return <NavCollapse {...item} key={index} className={classes.subCollapse} />;
          case 'item':
            return <NavMenuItem {...item} key={index} />;
          default:
            return null;
        }
      })}
    </List>
  ) : null;

  const moveToLink = () => {
    navigate(link);
  };

  const MenuCollapse = (
    <ListItem
      component="div"
      disableGutters
      className={clsx(classes.navCollapseBtn, `${open ? 'active' : ''}`, 'Cmt-navCollapseBtn')}>
      <Box component="span" className={classes.navCollapseBtnInner} onClick={moveToLink}>
        {renderIcon()}
        <Box component="span" className={classes.navText}>
          {name}
        </Box>
        {/* {isExpandable && open && <ArrowDropDownIcon className={classes.navArrow} />}
        {isExpandable && !open && <ArrowDropUpIcon className={classes.navArrow} />} */}
      </Box>
      {MenuItemChildren}
    </ListItem>
  );

  return (
    <Box className={clsx(classes.navCollapse, 'Cmt-navCollapse', className, `${open ? 'active' : ''}`)}>{MenuCollapse}</Box>
  );
};

export default NavCollapse;
