import React, { useEffect } from 'react';
import { Box, CircularProgress, circularProgressClasses } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  circularProgressRoot: {
    position: 'fixed', // 스크롤 될 경우 로딩효과가 위에 남아있어서 fixed 로 수정
    left: 0,
    top: 0,
    zIndex: 9998, // react-toastify의 z-index가 9999이므로 1 작게 설정
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(0, 0, 0, 0.2)', // 옅은 회색 백그라운드 추가
  },
}));

// 로딩 효과 수정
function MatrixCircularProgress(props) {
  return (
    <Box sx={{ position: 'relative' }}>
      {/* 배경 원 */}
      <CircularProgress
        variant="determinate"
        sx={{
          color: theme => theme.palette.grey[theme.palette.mode === 'light' ? 300 : 800],
        }}
        size={50}
        thickness={4}
        value={100} // 배경원 -> 100%로 설정
        {...props}
      />

      {/* 회전 원 */}
      <CircularProgress
        variant="indeterminate"
        sx={{
          // color: theme => (theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8'),
          // animationDuration: '550ms',
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
        }}
        size={50}
        thickness={4}
        {...props}
      />
    </Box>
  );
}

const PageLoader = () => {
  const classes = useStyles();
  /**
   * page loading 시 키보드 이벤트 제어
   */
  const handleKeyDown = e => {
    e.preventDefault();
  };
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <Box className={classes.circularProgressRoot}>
      <MatrixCircularProgress />
    </Box>
  );
};

export default PageLoader;
