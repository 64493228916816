import { makeStyles } from '@mui/styles';

// const boxShadow = '0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12)';
const commonModalStyle = makeStyles(theme => ({
  dialogButton: {
    width: 105,
  },
  alertConfirm: {
    // 최소넓이, 여백, 글씨 진하게
    '& .MuiDialogContentText-root': {
      minWidth: 300,
      marginTop: theme.spacing(2),
      padding: theme.spacing(1),
      fontWeight: 'bold',
      color: 'rgba(0, 0, 0, 0.85)',
      whiteSpace: 'pre-wrap',
    },
  },
  // @DEPRECATED - CommonModal 에 사용되던 값
  // modalArea: {
  //   position: 'absolute',
  //   top: '50%',
  //   left: '50%',
  //   transform: 'translate(-50%, -50%)',
  //   width: 400,
  //   background: 'white',
  //   // 테두리는 좀 이상해서 제거 - dialog와 맞춰줌
  //   // border: '2px solid #000',
  //   boxShadow: boxShadow,
  //   // padding: '1rem', // padding은 기본 지원 ui가 깨져서 제거
  //   borderRadius: 4, // dialog와 맞춰줌
  //   [theme.breakpoints.up('md')]: {
  //     width: 720,
  //   },
  //   [theme.breakpoints.up('lg')]: {
  //     width: 1024,
  //   },
  //   [theme.breakpoints.up('xl')]: {
  //     width: 1280,
  //   },
  // },
}));

export default commonModalStyle;
