import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import MDialogTitle from 'components/Dialog/MDialogTitle';
import React from 'react';
import { makeStyles } from '@mui/styles';
import commonModalStyle from 'common/Modals/styles/CommonModal.style';

// 기본 옵션
const defaultOptions = {
  dividers: false, // contents 위아래에 dividers를 넣을지 여부
  removeCancel: false, // 캔슬버튼을 제거할지 여부
  fullWidth: false, // fullWidth 가 true 일 경우에만 maxWidth 가 작동한다. / false 일 경우 내부 요소에 따라 늘어나는 구조인듯
  maxWidth: false, // dialog Size 기본값
  width: null, // 어쩔수 없이 필요할때 사용 - 그외 maxWidth 사용
  centerButton: false, // 버튼을 가운데 정렬할지 여부
  noTitleCloseButton: false, // 타이틀 없이 닫기 버튼만 사용
  actionButtonPadding: 22, // 액션 버튼의 padding - MuiDialogActions-root 의 padding 기본값은 8
  backdropClick: true, // 기본적으로 배경 클릭시 모달 종료 - false일 경우 버튼으로만 종료 가능
};

// dialog Size 목록
export const maxWidthList = ['xs', 'sm', 'md', 'lg', 'xl'];

/**
 * 공통 모달 (Dialog)
 * MUI 에서 Modal 을 한번 wrapping 한 컴포넌트로,
 * 출력 형태가 기본 Modal 보다 조금 더 자연스럽고 개선된것 같습니다.
 *
 * 내부 구성요소에 맞게 늘어나려면 maxWidth: false (기본값)을 사용
 * 고정된 사이즈를 사용하고자 한다면 maxWidth를 maxWidthList 값중 하나로 사용 (정상 세팅시 fullWidth 는 자동 true 세팅)
 *
 * @param open 모달 오픈 여부
 * @param onClose 모달 닫기 콜백
 * @param title 타이틀
 * @param children 컨텐츠
 * @param actionButtons 액션 버튼 (name, callback 을 넘기거나 React Element 를 넘길수 있음)
 * @param options 추가 옵션
 * @param rest 추가적으로 Dialog에 적용하고자 하는 옵션
 * @returns {JSX.Element}
 * @constructor
 */
export const CommonDialog = ({ open, onClose, title, children, actionButtons, options, ...rest }) => {
  // 오픈 여부 세팅 - 기본값 false
  if (open !== true) open = false;

  // 옵션 병합 - parameter 에 기본값으로 세팅하면 warning 발생
  const _options = Object.assign({}, defaultOptions, options);

  // 너비가 지정되어 있다면 적용
  const classes = commonModalStyle();

  if (!actionButtons) actionButtons = [];

  // 캔슬버튼 제거 옵션이 없다면 기본적으로 캔슬버튼을 앞쪽에 추가한다.
  if (!_options?.removeCancel)
    actionButtons = [{ name: '취소', callback: onClose, variant: 'outlined', color: 'secondary' }, ...actionButtons];

  // fullWidth 와 maxWidth 옵션 처리
  // 유효 옵션이 있으면 세팅한것으로 간주
  if (maxWidthList.indexOf(_options.maxWidth) !== -1) {
    _options.fullWidth = true;
  } else if (!_options.fullWidth) {
    // 유효 옵션이 없고 fullWidth 가 유효하지 않은 경우 false 로 세팅
    _options.fullWidth = false;
    _options.maxWidth = false;
  } else if (maxWidthList.indexOf(_options.maxWidth) === -1) {
    // fullWith 가 true 인데 maxWidth 값이 올바르지 않거나 없을 경우 기본값 md 로 세팅
    _options.fullWidth = true;
    _options.maxWidth = 'md'; // fullWidth가 true 일 경우에만 maxWidth 가 작동
  }

  // 하단 버튼 스타일 세팅
  const actionButtonAreaStyle = { root: {} };
  if (_options.centerButton === true) actionButtonAreaStyle.root.justifyContent = 'center !important'; // 가운데 버튼
  if (!!_options.actionButtonPadding) actionButtonAreaStyle.root.padding = `${_options.actionButtonPadding}px !important`; // 패딩
  const actionButtonAreaClasses = makeStyles(theme => actionButtonAreaStyle)();

  // 배경클릭 닫기 방지 상태 처리
  const _onClose = (event, reason) => {
    if (reason === 'backdropClick' && _options.backdropClick === false) return;
    onClose(event, reason);
  };

  return (
    // maxWidth 에는 false, xs, sm, md, lg, xl 5가지 값이 존재하며,
    // fullWidth 값이 true일 경우에만 작동한다.
    <Dialog open={open} onClose={_onClose} maxWidth={_options.maxWidth} fullWidth={_options.fullWidth} {...rest}>
      {/* 타이틀 영역 */}
      {title && <MDialogTitle onClose={_onClose}>{title}</MDialogTitle>}
      {/* 타이틀 없이 x 버튼만 사용 - 타이틀이 있을땐 true 여도 제거 */}
      {!title && _options.noTitleCloseButton === true && <MDialogTitle onClose={_onClose} />}

      {/* 컨텐츠 영역 */}
      <DialogContent dividers={_options?.dividers}>{children}</DialogContent>

      {/* 액션 버튼 영역 */}
      <DialogActions className={actionButtonAreaClasses.root}>
        {actionButtons.map((button, index) => {
          // console.log(typeof button, button, React.isValidElement(button));

          // 리엑트 엘리먼트가 넘어온경우 그냥 그리기
          return React.isValidElement(button) ? (
            <React.Fragment key={index}>{button}</React.Fragment>
          ) : (
            <Button
              key={index}
              variant={button.variant ? button.variant : 'contained'}
              size={button.size ? button.size : 'small'}
              color={button.color ? button.color : 'primary'}
              disabled={button.disabled}
              className={classes.dialogButton}
              onClick={button.callback ? () => button.callback() : null}>
              {button.name}
            </Button>
          );
        })}
      </DialogActions>
    </Dialog>
  );
};
