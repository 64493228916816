import React from 'react';
import clsx from 'clsx';

import SideBar from '@jumbo/components/AppLayout/partials/SideBar';
import MainHeader from './MainHeader';
import useStyles from './index.style';
// import Footer from '@jumbo/components/AppLayout/partials/Footer';
import { HEADER_TYPE } from '@jumbo/constants/ThemeOptions';

import CmtHorizontalLayout from '@coremat/CmtLayouts/Horizontal';
import CmtHeaderMain from '@coremat/CmtLayouts/Horizontal/Header/HeaderMain';
import CmtHeader from '@coremat/CmtLayouts/Horizontal/Header';
import CmtSidebar from '@coremat/CmtLayouts/Horizontal/Sidebar';
import CmtContent from '@coremat/CmtLayouts/Horizontal/Content';
// import CmtFooter from '@coremat/CmtLayouts/Horizontal/Footer';
import defaultContext from '@jumbo/components/contextProvider/AppContextProvider/defaultContext';
import PageChildrenBtnWrap from 'components/Common/PageChildrenBtnWrap';
import { useLocation } from 'react-router-dom';
import { whoAmI } from '@jumbo/components/AppLayout/partials/menus';

const layoutOptions = {
  showFooter: true,
  headerType: HEADER_TYPE.STATIC,
  layoutStyle: defaultContext.layoutType,
};

const HorizontalMinimal = ({ className, children }) => {
  const classes = useStyles();
  const location = useLocation();

  React.useEffect(() => {
    // 메뉴 라이브러리의 메뉴찾기 기능으로 찾기
    const imFoundMenu = whoAmI(location.pathname);
    if (!!imFoundMenu) document.title = imFoundMenu.menu?.name?.props?.children;
  }, [location]);

  return (
    <CmtHorizontalLayout
      layoutOptions={layoutOptions}
      className={clsx('Cmt-horizontalMinimalLayout', className)}
      header={
        <CmtHeader className={classes.appHeaderDark}>
          <CmtHeaderMain>
            <MainHeader />
          </CmtHeaderMain>
        </CmtHeader>
      }
      // footer={
      //   <CmtFooter type="static">
      //     <Footer />
      //   </CmtFooter>
      // }
      sidebar={
        <CmtSidebar>
          <SideBar />
        </CmtSidebar>
      }>
      <CmtContent>
        <PageChildrenBtnWrap /> {/* 하위메뉴 */}
        {children}
      </CmtContent>
    </CmtHorizontalLayout>
  );
};

export default HorizontalMinimal;
