import { combineReducers } from 'redux';
//import { connectRouter } from 'connected-react-router';

import Common from './Common';
import Auth from './Auth';
import History from './History';

import WareHousing from './WareHousing';
import Shipments from './Shipments';
import WholesaleReturn from './WholesaleReturn';
import RetailReturn from './RetailReturn';
import Product from './Product';
import Inventory from './Inventory';
import ProductHistory from './ProductHistory';
import Setting from './Setting';
import RealPacking from './RealPacking';

export default routerReducer =>
  combineReducers({
    router: routerReducer,
    history: History,
    common: Common,
    auth: Auth,
    wareHousing: WareHousing,
    shipments: Shipments,
    wholesaleReturn: WholesaleReturn,
    retailReturn: RetailReturn,
    product: Product,
    inventory: Inventory,
    productHistory: ProductHistory,
    setting: Setting,
    realPacking: RealPacking,
  });
