import {
  SET_WAREHOUSING_INSPECTION_TAB,
  SET_WAREHOUSING_INSPECTION_PURCHASE,
  EDIT_WAREHOUSING_INSPECTION_PURCHASE,
  SET_WAREHOUSING_INSPECTION_MISONG,
  EDIT_WAREHOUSING_INSPECTION_MISONG,
  EDIT_PRICE_WAREHOUSING_INSPECTION_PURCHASE,
  EDIT_STOCK_WAREHOUSING_INSPECTION_PURCHASE,
  EDIT_JANGKKIGROUP_WAREHOUSING_INSPECTION_PURCHASE,
  EDIT_PIECEGROUP_WAREHOUSING_INSPECTION_PURCHASE,
  EDIT_INSTOCKGROUP_WAREHOUSING_INSPECTION_PURCHASE,
  DELETE_WAREHOUSING_DETAIL_INFORMATION,
  PUT_CONFIRM_ALL,
  PUT_CONFIRM_BY_ID,
  PUT_CONFIRM_BY_OWNER,
  SET_WAREHOUSING_DETAIL_INFORMATION,
  SET_WAREHOUSING_FIXED,
  SET_WAREHOUSING_FIXED_SUMMARY,
  SET_WAREHOUSING_WHOLESALE_LIST,
  SET_WAREHOUSING_TRACKING, // 입고 진행현황
  CANCEL_FORCESTOCK_WAREHOUSING_TRACKING,
  SET_WAREHOUSING_PURCHASE,
  SET_WAREHOUSING_STANDBY,
  SET_WAREHOUSING_STANDBY_SUMMARY,
  GET_PACKING_TABLE_PRODUCT,
  GET_REPAIR_TABLE_PRODUCT,
  GET_SELF_WAREHOUSING_PURCHASE_LIST,
  GET_SELF_WAREHOUSING_WAITING_LIST,
  GET_SELF_WAREHOUSING_REQUEST_DETAIL_LIST,
  GET_SELF_WAREHOUSING_REQUEST_DETAIL,
  GET_SELF_WAREHOUSING_PER_PRODUCT_LIST,
  GET_SELF_WAREHOUSING_PER_ROTATE_LIST,
  SET_CHANGED_INSTOCK,
  GET_SELLER_CHECK_TABLE_PRODUCT,
  GET_SELLER_CONFIRM_TABLE_ARRIVE,
  GET_WHOLESALE_RETURN_TABLE_ARRIVE,
  GET_PACKING_TABLE_ARRIVE,
  GET_REPAIR_TABLE_ARRIVE,
  SET_SORTING_WAREHOUSING_TRACKING_CONFIRMABLE,
  SET_SORTING_WAREHOUSING_TRACKING_CONFIRMDISABLE,
  EDIT_SELF_WAREHOUSING_REQUEST_DETAIL_LIST,
  GET_WHOLESALE_RETURN_PRODUCT_HISTORY,
  GET_SELLER_CONFIRM_PRODUCT_HISTORY,
  GET_RESET_TABLE_MOVE_INFO,
  GET_AFTER_WHOLESALE_RETURN_TABLE_ARRIVE,
  GET_SELLER_CONFIRM_TABLE_ARRIVE_HISTORY,
  GET_SELLER_CONFIRM_TABLE_ARRIVE_MANY,
  GET_SELLER_CONFIRM_TABLE_ARRIVE_MANY_UNSELECTED_LIST,
  GET_SELLER_CONFIRM_TABLE_ARRIVE_MANY_SELECTED_LIST,
  GET_WHOLESALE_RETURN_TABLE_ARRIVE_MANY,
  GET_WHOLESALE_RETURN_TABLE_ARRIVE_MANY_UNSELECTED_LIST,
  GET_WHOLESALE_RETURN_TABLE_ARRIVE_MANY_SELECTED_LIST,
  GET_WHOLESALE_RETURN_TABLE_ARRIVE_HISTORY,
  GET_DIRECT_SELLER_CONFIRM_TABLE_ARRIVE,
  GET_DIRECT_SELLER_CONFIRM_TABLE_PRODUCT,
  SET_WAREHOUSING_TRACKING_FILTER,
  SET_WAREHOUSING_TRACKING_PAGINATION,
  GET_WHOLESALE_RETURN_TABLE_PRODUCT,
  GET_RETURN_PRODUCT_MANAGEMENT,
  DELETE_RETURN_PRODUCT_MANAGEMENT,
  DELETE_SELLER_CONFIRM_TABLE_ARRIVE_MANY,
  DELETE_DIRECT_SELLER_CONFIRM_TABLE_ARRIVE,
} from '@jumbo/constants/ActionTypes';

import { types as wareHousingTypes } from 'redux/actions/WareHousing';

const INIT_STATE = {
  warehousing_purchase: [],

  warehousing_standby: [],
  warehousing_standby_summary: [],

  warehousing_inspection_tab: 'purchase',
  warehousing_inspection_purchase: [],
  warehousing_inspection_misong: [],
  warehousing_wholesale_list: [],

  warehousing_confirm: [],
  warehousing_confirm_summary: [],
  warehousing_fixed: [],
  warehousing_fixed_summary: [],

  warehousing_tracking: [],
  warehousing_tracking_filter: null,
  warehousing_tracking_pagination: null,
  warehousing_tracking_cancel: [],

  warehousing_detail_information: null,
  issue_supplier_return_history: null,
  packing_table_arrive: {},
  packing_table_product: [],
  packing_repair_product: [],
  seller_check_table_product: [],
  seller_confirm_table_arrive: null,
  seller_confirm_table_arrive_many: null,
  seller_confirm_table_arrive_history: null,
  seller_confirm_table_arrive_many_unselected_list: null,
  seller_confirm_table_arrive_many_selected_list: null,
  repair_table_arrive: null,
  wholesale_return_table_arrive: null,
  wholesale_return_table_arrive_history: null,
  wholesale_return_table_product_history: [], //과거이력
  reset_table_move_info: null,
  after_wholesale_return_table_arrive: null,
  wholesale_return_table_arrive_many: null,
  wholesale_return_table_arrive_many_unselected_list: null,
  wholesale_return_table_arrive_many_selected_list: null,

  self_warehousing_purchase_list: [],
  isSelfWarehousingPurchaseListReload: false, // 직접입고 새로고침 여부
  self_warehousing_waiting_list: [],
  self_warehousing_request_detail: {},
  self_warehousing_request_detail_list: [],

  self_warehousing_per_product_list: [],
  self_warehousing_per_rotate_list: [],

  wholesale_return_table_history: [],
  seller_confirm_table_history: [],

  direct_seller_confirm_table_arrive: null,
  direct_seller_confirm_table_product: [],
  return_product_management: null,
  return_product_management_history: [],
};

export default (state = INIT_STATE, action, dispatch) => {
  switch (action.type) {
    // purchase warehousing
    case SET_WAREHOUSING_PURCHASE: {
      return {
        ...state,
        warehousing_purchase: action.payload,
      };
    }

    // standby warehousing
    case SET_WAREHOUSING_STANDBY: {
      return {
        ...state,
        warehousing_standby: action.payload,
      };
    }
    case SET_WAREHOUSING_STANDBY_SUMMARY: {
      return {
        ...state,
        warehousing_standby_summary: action.payload,
      };
    }

    // 입고 검수대기 탭 변경
    case SET_WAREHOUSING_INSPECTION_TAB: {
      if (!['purchase', 'misong', 'return'].includes(action.payload)) {
        // console.error(`지원하지 않는 purchase_type입니다. (${action.payload})`);
        return state;
      }

      return {
        ...state,
        warehousing_inspection_tab: action.payload,
      };
    }

    // 신규 주문
    case SET_WAREHOUSING_INSPECTION_PURCHASE: {
      return {
        ...state,
        warehousing_inspection_purchase: action.payload,
      };
    }
    // 주문 수정
    case EDIT_WAREHOUSING_INSPECTION_PURCHASE: {
      const _warehousing_inspection_purchase = [...state.warehousing_inspection_purchase];

      if (Array.isArray(action.payload))
        action.payload.forEach(({ id, ...data }) => {
          const target_inspection_purchase = _warehousing_inspection_purchase.find(purchase => purchase.id === id);
          if (!target_inspection_purchase) return;

          Object.assign(target_inspection_purchase, data);
        });
      else {
        const target_inspection_purchase = _warehousing_inspection_purchase.find(
          purchase => purchase.id === action.payload.id,
        );

        Object.assign(target_inspection_purchase, action.payload);
      }

      return {
        ...state,
        warehousing_inspection_purchase: _warehousing_inspection_purchase,
      };
    }
    // 미송 주문
    case SET_WAREHOUSING_INSPECTION_MISONG: {
      return {
        ...state,
        warehousing_inspection_misong: action.payload,
      };
    }
    // 주문 수정
    case EDIT_WAREHOUSING_INSPECTION_MISONG: {
      const _warehousing_inspection_misong = [...state.warehousing_inspection_misong];

      if (Array.isArray(action.payload))
        action.payload.forEach(({ id, ...data }) => {
          const target_inspection_misong = _warehousing_inspection_misong.find(misong => misong.id === id);
          if (!target_inspection_misong) return;

          Object.assign(target_inspection_misong, data);
        });
      else {
        const target_inspection_misong = _warehousing_inspection_misong.find(misong => misong.id === action.payload.id);

        Object.assign(target_inspection_misong, action.payload);
      }

      return {
        ...state,
        warehousing_inspection_misong: _warehousing_inspection_misong,
      };
    }

    // 도매 단가 수정
    case EDIT_PRICE_WAREHOUSING_INSPECTION_PURCHASE: {
      const index = state.warehousing_inspection_purchase.findIndex(p => p.id === action.payload.id);
      const _warehousing_inspection_purchase = [...state.warehousing_inspection_purchase];
      // _warehousing_inspection_purchase[index] = action.payload;
      _warehousing_inspection_purchase[index].buy_price = action.payload.buy_price;
      return {
        ...state,
        warehousing_inspection_purchase: _warehousing_inspection_purchase,
      };
    }
    // 도매 단가 수정
    case EDIT_STOCK_WAREHOUSING_INSPECTION_PURCHASE: {
      return {
        ...state,
        warehousing_inspection_purchase: action.payload,
      };
    }
    // 마스터 그룹 수정
    case EDIT_JANGKKIGROUP_WAREHOUSING_INSPECTION_PURCHASE: {
      const _warehousing_inspection_purchase = [...state.warehousing_inspection_purchase];

      if (Array.isArray(action.payload))
        action.payload.forEach(({ id, ...data }) => {
          const target_inspection_purchase = _warehousing_inspection_purchase.find(purchase => purchase.id === id);
          if (!target_inspection_purchase) return;

          Object.assign(target_inspection_purchase, data);
        });
      else {
        const target_inspection_purchase = _warehousing_inspection_purchase.find(
          purchase => purchase.id === action.payload.id,
        );

        Object.assign(target_inspection_purchase, action.payload);
      }

      return {
        ...state,
        warehousing_inspection_purchase: _warehousing_inspection_purchase,
      };
    }
    case EDIT_PIECEGROUP_WAREHOUSING_INSPECTION_PURCHASE: {
      const index = state.warehousing_inspection_purchase.findIndex(p => p.id === action.payload.id);
      const _warehousing_inspection_purchase = state.warehousing_inspection_purchase;
      _warehousing_inspection_purchase[index] = action.payload;
      return {
        ...state,
        warehousing_inspection_purchase: _warehousing_inspection_purchase,
      };
    }
    case EDIT_INSTOCKGROUP_WAREHOUSING_INSPECTION_PURCHASE: {
      const index = state.warehousing_inspection_purchase.findIndex(p => p.id === action.payload.id);
      const _warehousing_inspection_purchase = state.warehousing_inspection_purchase;
      _warehousing_inspection_purchase[index] = action.payload;
      return {
        ...state,
        warehousing_inspection_purchase: _warehousing_inspection_purchase,
      };
    }
    case SET_WAREHOUSING_WHOLESALE_LIST: {
      return {
        ...state,
        warehousing_wholesale_list: action.payload,
      };
    }

    // confirm_warehousing
    case PUT_CONFIRM_BY_ID: {
      return {
        ...state,
        warehousing_confirm_summary: action.payload,
      };
    }
    case PUT_CONFIRM_BY_OWNER: {
      return {
        ...state,
        warehousing_confirm_summary: action.payload,
      };
    }
    case PUT_CONFIRM_ALL: {
      return {
        ...state,
        warehousing_confirm_summary: action.payload,
      };
    }
    case SET_WAREHOUSING_FIXED: {
      return {
        ...state,
        warehousing_fixed: action.payload,
      };
    }
    case SET_WAREHOUSING_FIXED_SUMMARY: {
      return {
        ...state,
        warehousing_fixed_summary: action.payload,
      };
    }
    case SET_WAREHOUSING_TRACKING: {
      return {
        ...state,
        warehousing_tracking: action.payload,
      };
    }
    case SET_WAREHOUSING_TRACKING_FILTER: {
      return {
        ...state,
        warehousing_tracking_filter: action.payload,
      };
    }
    case SET_WAREHOUSING_TRACKING_PAGINATION: {
      return {
        ...state,
        warehousing_tracking_pagination: action.payload,
      };
    }
    case SET_SORTING_WAREHOUSING_TRACKING_CONFIRMABLE: {
      const sorted_warehousing_tracking = state.warehousing_tracking.filter(p => p.is_force_packing);
      return {
        ...state,
        warehousing_tracking: sorted_warehousing_tracking,
      };
    }
    case SET_SORTING_WAREHOUSING_TRACKING_CONFIRMDISABLE: {
      const sorted_warehousing_tracking = state.warehousing_tracking.filter(
        p => !(p.in_stock !== 0 && p.status !== 'confirmed' && 'force_confirmed'),
      );
      return {
        ...state,
        warehousing_tracking: sorted_warehousing_tracking,
      };
    }
    case CANCEL_FORCESTOCK_WAREHOUSING_TRACKING: {
      // const index = state.warehousing_tracking.findIndex(p => p.id === action.payload.id);
      // const _warehousing_tracking = state.warehousing_tracking;
      // _warehousing_tracking[index].force_confirmable_stock = action.payload.force_confirmable_stock;
      return {
        ...state,
        warehousing_tracking_cancel: action.payload,
      };
    }
    case SET_WAREHOUSING_DETAIL_INFORMATION: {
      return {
        ...state,
        warehousing_detail_information: action.payload,
      };
    }
    case DELETE_WAREHOUSING_DETAIL_INFORMATION: {
      return {
        ...state,
        warehousing_detail_information: null,
      };
    }
    //입고 상품이동
    case GET_PACKING_TABLE_ARRIVE: {
      return {
        ...state,
        packing_table_arrive: action.payload,
      };
    }
    case GET_PACKING_TABLE_PRODUCT: {
      return {
        ...state,
        packing_table_product: action.payload,
      };
    }
    case GET_REPAIR_TABLE_ARRIVE: {
      return {
        ...state,
        repair_table_arrive: action.payload,
      };
    }
    case GET_REPAIR_TABLE_PRODUCT: {
      return {
        ...state,
        packing_repair_product: action.payload,
      };
    }
    case GET_WHOLESALE_RETURN_TABLE_PRODUCT: {
      return {
        ...state,
        wholesale_return_table_product: action.payload,
      };
    }
    //셀러확인테이블도착
    case GET_SELLER_CONFIRM_TABLE_ARRIVE: {
      return {
        ...state,
        seller_confirm_table_arrive: action.payload,
      };
    }
    case GET_SELLER_CONFIRM_TABLE_ARRIVE_HISTORY: {
      return {
        ...state,
        seller_confirm_table_arrive_history: action.payload,
      };
    }
    //셀러 테이블 도착 (대량)
    case GET_SELLER_CONFIRM_TABLE_ARRIVE_MANY: {
      return {
        ...state,
        seller_confirm_table_arrive_many: action.payload,
      };
    }
    case GET_SELLER_CONFIRM_TABLE_ARRIVE_MANY_UNSELECTED_LIST: {
      return {
        ...state,
        seller_confirm_table_arrive_many_unselected_list: action.payload,
      };
    }
    case GET_SELLER_CONFIRM_TABLE_ARRIVE_MANY_SELECTED_LIST: {
      return {
        ...state,
        seller_confirm_table_arrive_many_selected_list: action.payload,
      };
    }
    case GET_SELLER_CHECK_TABLE_PRODUCT: {
      return {
        ...state,
        seller_check_table_product: action.payload,
      };
    }
    case GET_WHOLESALE_RETURN_TABLE_ARRIVE: {
      return {
        ...state,
        wholesale_return_table_arrive: action.payload,
      };
    }
    //과거이력
    case GET_WHOLESALE_RETURN_PRODUCT_HISTORY: {
      return {
        ...state,
        wholesale_return_table_product_history: action.payload,
      };
    }
    case GET_RESET_TABLE_MOVE_INFO: {
      return {
        ...state,
        reset_table_move_info: action.payload,
      };
    }
    case GET_AFTER_WHOLESALE_RETURN_TABLE_ARRIVE: {
      return {
        ...state,
        after_wholesale_return_table_arrive: action.payload,
      };
    }
    case GET_WHOLESALE_RETURN_TABLE_ARRIVE_HISTORY: {
      return {
        ...state,
        wholesale_return_table_arrive_history: action.payload,
      };
    }

    //도매 반품 테이블 도착 (대량)
    case GET_WHOLESALE_RETURN_TABLE_ARRIVE_MANY: {
      return {
        ...state,
        wholesale_return_table_arrive_many: action.payload,
      };
    }
    case GET_WHOLESALE_RETURN_TABLE_ARRIVE_MANY_UNSELECTED_LIST: {
      return {
        ...state,
        wholesale_return_table_arrive_many_unselected_list: action.payload,
      };
    }
    case GET_WHOLESALE_RETURN_TABLE_ARRIVE_MANY_SELECTED_LIST: {
      return {
        ...state,
        wholesale_return_table_arrive_many_selected_list: action.payload,
      };
    }
    //직접입고
    case GET_SELF_WAREHOUSING_PURCHASE_LIST: {
      return {
        ...state,
        self_warehousing_purchase_list: action.payload,
      };
    }
    // 직접입고 목록 갱신
    case wareHousingTypes.SELF_WAREHOUSING_PURCHASE_LIST_RELOAD: {
      return {
        ...state,
        isSelfWarehousingPurchaseListReload: action.payload,
      };
    }
    case GET_SELF_WAREHOUSING_WAITING_LIST: {
      return {
        ...state,
        self_warehousing_waiting_list: action.payload,
      };
    }
    case GET_SELF_WAREHOUSING_REQUEST_DETAIL: {
      return {
        ...state,
        self_warehousing_request_detail: action.payload,
      };
    }
    case GET_SELF_WAREHOUSING_REQUEST_DETAIL_LIST: {
      return {
        ...state,
        self_warehousing_request_detail_list: action.payload,
      };
    }
    case EDIT_SELF_WAREHOUSING_REQUEST_DETAIL_LIST: {
      const _self_warehousing_request_detail_list = [...state.self_warehousing_request_detail_list];
      const target_request = _self_warehousing_request_detail_list.find(({ id }) => id === action.payload.id);
      if (!target_request) {
        console.error(`id가 일치하는 상품이 없습니다. ${JSON.stringify(action.payload, null, '\t')}`);
        return state;
      }

      Object.assign(target_request, action.payload);

      return {
        ...state,
        self_warehousing_request_detail_list: _self_warehousing_request_detail_list,
      };
    }
    case GET_SELF_WAREHOUSING_PER_PRODUCT_LIST: {
      return {
        ...state,
        self_warehousing_per_product_list: action.payload,
      };
    }
    case GET_SELF_WAREHOUSING_PER_ROTATE_LIST: {
      return {
        ...state,
        self_warehousing_per_rotate_list: action.payload,
      };
    }
    case SET_CHANGED_INSTOCK: {
      const index = state.self_warehousing_request_detail_list.findIndex(p => p.id === action.payload.id);

      const _self_warehousing_request_detail_list = state.self_warehousing_request_detail_list;
      _self_warehousing_request_detail_list[index] = action.payload;

      return {
        ...state,
        self_warehousing_request_detail_list: _self_warehousing_request_detail_list,
      };
    }
    case GET_DIRECT_SELLER_CONFIRM_TABLE_ARRIVE: {
      return {
        ...state,
        direct_seller_confirm_table_arrive: action.payload,
      };
    }
    case GET_DIRECT_SELLER_CONFIRM_TABLE_PRODUCT: {
      return {
        ...state,
        direct_seller_confirm_table_product: action.payload,
      };
    }
    case GET_SELLER_CONFIRM_PRODUCT_HISTORY: {
      return {
        ...state,
        seller_confirm_table_history: action.payload,
      };
    }
    case GET_RETURN_PRODUCT_MANAGEMENT: {
      return {
        ...state,
        return_product_management: action.payload,
      };
    }
    case DELETE_RETURN_PRODUCT_MANAGEMENT: {
      return {
        ...state,
        return_product_management: null,
      };
    }
    case DELETE_SELLER_CONFIRM_TABLE_ARRIVE_MANY: {
      return {
        ...state,
        seller_confirm_table_arrive_many: null,
      };
    }
    case DELETE_DIRECT_SELLER_CONFIRM_TABLE_ARRIVE: {
      return {
        ...state,
        direct_seller_confirm_table_arrive: null,
      };
    }
    default:
      return state;
  }
};
