/**
 * 공용 Enum 클래스
 * Enum 객체를 만들때 하기 클래스를 구현 후 바로 생성하여 제작한다.
 */
export default class Enum {
  constructor(enumObj) {
    // enum용 객체 비교함수 추가
    const equalFunc = function(object) {
      return object === this;
    };

    const notEqualFunc = function(object) {
      return object !== this;
    };

    // is, equal 함수 추가
    for (const enumElement of Object.values(enumObj)) {
      if (!enumElement.is) enumElement.is = equalFunc;
      if (!enumElement.isNot) enumElement.isNot = notEqualFunc;
      if (!enumElement.equal) enumElement.equal = equalFunc;
    }

    Object.assign(this, enumObj);
  }

  typeOf(type) {
    return Object.values(this).find(value => value.type === type);
  }

  nameOf(name) {
    return Object.values(this).find(value => value.name === name);
  }

  typeOfName(type) {
    const value = this.typeOf(type);
    return value ? value.name : type;
  }

  toArray(excludes = []) {
    return Object.values(this).filter(value => !excludes.includes(value));
  }
}
