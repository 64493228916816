import React, { cloneElement, isValidElement } from 'react';
import { Box, List } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { addPageHistory } from 'redux/actions/History';

const useStyles = makeStyles(theme => ({
  navMenuLink: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 14px 8px 14px',
    color: theme.palette.horizontalNav.textColor,
    '&:hover, &:focus': {
      color: theme.palette.horizontalNav.textDarkColor,
      backgroundColor: theme.palette.horizontalNav.menuHoverBgColor,
      '& .Cmt-icon-root': {
        color: theme.palette.horizontalNav.textDarkColor,
      },
    },
    '&.active': {
      color: theme.palette.horizontalNav.textDarkColor,
      backgroundColor: theme.palette.horizontalNav.menuHoverBgColor,
      '& .Cmt-icon-root': {
        color: theme.palette.horizontalNav.textActiveColor,
      },
      '&:hover, &:focus': {
        '& .Cmt-icon-root': {
          color: theme.palette.horizontalNav.textActiveColor,
        },
      },
    },
  },
  iconRoot: {
    marginRight: 16,
    fontSize: 20,
  },
}));

const NavMenuItem = props => {
  const { name, icon, link, handleClick } = props;
  const classes = useStyles();

  const renderIcon = () => {
    if (icon && isValidElement(icon)) {
      return cloneElement(icon, {
        className: clsx(classes.iconRoot, 'Cmt-icon-root'),
      });
    }

    return null;
  };

  const dispatch = useDispatch();

  const handleLinkClick = () => {
    const page_info = {
      pathname: link,
      pathlabel: name.props.children,
      filter: {},
    };
    dispatch(addPageHistory(page_info));
  };

  return (
    <List component="div" disablePadding onClick={handleClick}>
      <NavLink
        className={clsx(classes.navMenuLink, 'Cmt-nav-menu-link')}
        to={link}
        onClick={() => {
          handleLinkClick();
        }}>
        {/* Display an icon if any */}
        {renderIcon()}
        <Box component="span" className={clsx(classes.navText, 'Cmt-nav-text')}>
          {name}
        </Box>
      </NavLink>
    </List>
  );
};

export default NavMenuItem;
