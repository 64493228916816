import React from 'react';
import { Navigate, useRoutes } from 'react-router';
import loadable from '@loadable/component';

const Purchase = loadable(() => import('./Purchase'));
const SelfPurchase = loadable(() => import('./SelfPurchase'));
const Error404 = loadable(() => import('routes/Errors/404'));

const routes = [
  { index: true, element: <Navigate replace to={'purchase'} /> },
  { path: 'purchase/*', element: <Purchase /> },
  { path: 'self/*', element: <SelfPurchase /> },
  { path: '*', element: <Error404 /> },
];

const WareHousing = () => {
  return useRoutes(routes);
};

export default WareHousing;
