import React from 'react';
import { ToastContainer, Slide, toast } from 'react-toastify'; // react-script 버전이 낮아서 9.0.3 이후 버전은 사용불가 (webpack 5 필요)
import 'react-toastify/dist/ReactToastify.css';
import './toastify.css';
import { Box } from '@mui/material'; // css 커스터마이징

export const ToastContainerWrapper = () => {
  return (
    <ToastContainer
      position="top-center"
      autoClose={2000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
      transition={Slide}
    />
  );
};

export const coloredToast = {
  toast: (message, options) =>
    toast(message, {
      ...options,
      theme: 'colored',
    }),
  success: (message, options) =>
    toast.success(message, {
      ...options,
      theme: 'colored',
    }),
  info: (message, options) =>
    toast.info(message, {
      ...options,
      theme: 'colored',
    }),
  error: (message, options) =>
    toast.error(message, {
      ...options,
      theme: 'colored',
    }),
  warning: (message, options) =>
    toast.warning(message, {
      ...options,
      theme: 'colored',
    }),
  warn: (message, options) =>
    toast.warn(message, {
      ...options,
      theme: 'colored',
    }),
};

export const darkToast = {
  toast: (message, options) =>
    toast(message, {
      ...options,
      theme: 'dark',
    }),
  success: (message, options) =>
    toast.success(message, {
      ...options,
      theme: 'dark',
    }),
  info: (message, options) =>
    toast.info(message, {
      ...options,
      theme: 'dark',
    }),
  error: (message, options) =>
    toast.error(message, {
      ...options,
      theme: 'dark',
    }),
  warning: (message, options) =>
    toast.warning(message, {
      ...options,
      theme: 'dark',
    }),
  warn: (message, options) =>
    toast.warn(message, {
      ...options,
      theme: 'dark',
    }),
};

export const objectToToastJSX = (object, type = 'normal') => {
  console.log(object);
  const types = {
    normal: _object => (
      <Box>
        {Object.keys(object).map(key => (
          <p>{`${key}: ${object[key]}`}</p>
        ))}
      </Box>
    ),
  };

  return types[type] ? types[type](object) : '';
};
