import { ADD_PAGE_HISTORY, SAVE_TABLE_STATE, DELETE_PAGE_HISTORY, PUSH_HISTORY } from '@jumbo/constants/ActionTypes';

export const addPageHistory = page => {
  return dispatch => {
    dispatch({
      type: ADD_PAGE_HISTORY,
      payload: page,
    });
  };
};

export const deletePageHistory = page => {
  return dispatch => {
    dispatch({
      type: DELETE_PAGE_HISTORY,
      payload: page,
    });
  };
};

export const saveTableState = (location, filter, column) => {
  return dispatch => {
    dispatch({
      type: SAVE_TABLE_STATE,
      payload: { location: location, filter: filter, column: column },
    });
  };
};

export const pushHistory = (url, isMoved) => {
  return dispatch => {
    dispatch({
      type: PUSH_HISTORY,
      url: url,
      isMoved: isMoved === true,
    });
  };
};
