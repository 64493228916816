import React from 'react';
import { Navigate, useRoutes } from 'react-router';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import loadable from '@loadable/component';

const InventoryCheck = loadable(() => import('./InventoryCheck'));
const InventoryCheckDetail = loadable(() => import('./InventoryCheck/pages/InventoryCheckDetail'));
const SellerCheck = loadable(() => import('./SellerCheck'));
const SellerCheckDetail = loadable(() => import('./SellerCheck/pages/SellerCheckDetail'));
const MovingLocationAll = loadable(() => import('./MovingLocationAll'));
const MovingLocation = loadable(() => import('./MovingLocation'));
const MovingHistory = loadable(() => import('./MovingHistory'));
const RequestDecrease = loadable(() => import('./RequestDecrease'));
const RequestIncrease = loadable(() => import('./RequestIncrease'));
const NotFound = loadable(() => import('routes/Errors/404'));

const ProductRoutes = () => {
  const routes = [
    { index: true, element: <Navigate to="inventory-check" replace /> },
    { path: 'inventory-check', element: <InventoryCheck /> },
    { path: 'inventory-check/:id', element: <InventoryCheckDetail /> },
    { path: 'seller-check', element: <SellerCheck /> },
    { path: 'seller-check/:id', element: <SellerCheckDetail /> },
    { path: 'moving-location-all', element: <MovingLocationAll /> },
    { path: 'moving-location', element: <MovingLocation /> },
    { path: 'moving-history', element: <MovingHistory /> },
    { path: 'request-decrease', element: <RequestDecrease /> },
    { path: 'request-increase', element: <RequestIncrease /> },
    { path: '*', element: <NotFound /> },
  ];

  return <PageContainer>{useRoutes(routes)}</PageContainer>;
};

export default ProductRoutes;
