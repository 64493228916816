import React from 'react';
import { Navigate, useRoutes } from 'react-router';
import loadable from '@loadable/component';

const WarehousingRoutes = loadable(() => import('./Warehousing'));
const ShipmentRoutes = loadable(() => import('./Shipment'));
const LocationsRoutes = loadable(() => import('./Locations'));
const UserRoutes = loadable(() => import('./User'));
const BarcodesRoutes = loadable(() => import('./Barcodes'));
const NotFound = loadable(() => import('routes/Errors/404'));

const SettingRoutes = () => {
  const routes = [
    { index: true, element: <Navigate to="warehousing" replace /> },
    { path: 'warehousing/*', element: <WarehousingRoutes /> },
    { path: 'shipment/*', element: <ShipmentRoutes /> },
    { path: 'locations/*', element: <LocationsRoutes /> },
    { path: 'user/*', element: <UserRoutes /> },
    { path: 'barcodes/*', element: <BarcodesRoutes /> },
    { path: '*', element: <NotFound /> },
  ];

  return useRoutes(routes);
};

export default SettingRoutes;
