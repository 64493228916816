// import { applyMiddleware, createStore, combineReducers } from 'redux';
import { applyMiddleware, createStore } from 'redux'; // warning 수정
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
// import { createReduxHistoryContext, reachify } from 'redux-first-history';
import { createReduxHistoryContext } from 'redux-first-history'; // warning 수정
import reducers from '../reducers';
import storage from 'redux-persist/lib/storage';
import persistReducer from 'redux-persist/es/persistReducer';
import persistStore from 'redux-persist/es/persistStore';

// const history = createBrowserHistory();
// const routeMiddleware = routerMiddleware(history);
const bindMiddleware = middleware => {
  if (process.env.NODE_ENV !== 'production' && localStorage.getItem('isReduxLogOn') === 'true') {
    const { composeWithDevTools } = require('redux-devtools-extension');
    const { logger } = require('redux-logger');
    middleware.push(logger);
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['productHistory'],
};

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory(),
  //other options if needed
});
const persistedReducer = persistReducer(persistConfig, reducers(routerReducer));
export const store = createStore(persistedReducer, {}, bindMiddleware([routerMiddleware, thunk]));
export const history = createReduxHistory(store);
export const persistor = persistStore(store);
