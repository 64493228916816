import React from 'react';
import clsx from 'clsx';
import { Box, IconButton } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { useDispatch } from 'react-redux';
import { AuhMethods } from 'services/auth';
import { CurrentAuthMethod } from '@jumbo/constants/AppConstants';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';

const useStyles = makeStyles(theme => ({
  profileRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 10,
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      paddingLeft: 20,
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 2,
      zIndex: 1,
      height: 35,
      width: 1,
      backgroundColor: alpha(theme.palette.common.dark, 0.15),
    },
  },
}));

const UserDropDown = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleLogout = e => {
    e.preventDefault();
    bc.postMessage('logout');
    dispatch(AuhMethods[CurrentAuthMethod].onLogout());
  };

  const bc = new BroadcastChannel('logout');
  bc.onmessage = event => {
    dispatch(AuhMethods[CurrentAuthMethod].onLogout());
  };

  return (
    <Box className={clsx(classes.profileRoot, 'Cmt-profile-pic')}>
      <IconButton onClick={handleLogout}>
        <PowerSettingsNewIcon color="secondary" />
      </IconButton>
    </Box>
  );
};

export default UserDropDown;
