import React from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router';
import loadable from '@loadable/component';
import { useSelector } from 'react-redux';

import WareHousingRoutes from './WareHousing';
import ShipmentRoutes from './Shipment';
import WholesaleReturnRoutes from './WholesaleReturn';
import RetailReturnRoutes from './RetailReturn';
import ProductRoutes from './Product';
import InventoryRoutes from './Inventory';
import SettingRoutes from './Setting';
import MobileRoutes from './Mobile';
import DemoRoutes from './Demo';
const Login = loadable(() => import('./Auth/Login'));
const Error404 = loadable(() => import('routes/Errors/404'));

const RestrictedRoute = ({ component: Component }) => {
  const location = useLocation();
  const { authUser } = useSelector(({ auth }) => auth);
  return authUser ? <Component /> : <Navigate replace to={'/signin'} state={{ from: location.pathname }} />;
};

const WmsRoutes = () => {
  const { authUser } = useSelector(({ auth }) => auth);
  const navigate = useNavigate();
  const location = useLocation();

  if (!authUser && location.pathname !== '/signin') {
    navigate('/signin', { replace: true });
  }

  if (authUser && location.pathname === '/signin') {
    navigate('/warehousing-management', { replace: true });
  }

  return (
    <Routes>
      <Route index element={<Navigate replace to={'/warehousing-management'} />} />
      <Route path="warehousing-management/*" element={<RestrictedRoute component={WareHousingRoutes} />} />
      <Route path="shipment-management/*" element={<RestrictedRoute component={ShipmentRoutes} />} />
      <Route path="wholesale-return/*" element={<RestrictedRoute component={WholesaleReturnRoutes} />} />
      <Route path="retail-return/*" element={<RestrictedRoute component={RetailReturnRoutes} />} />
      <Route path="product/*" element={<RestrictedRoute component={ProductRoutes} />} />
      <Route path="inventory/*" element={<RestrictedRoute component={InventoryRoutes} />} />
      <Route path="setting/*" element={<RestrictedRoute component={SettingRoutes} />} />
      <Route path="m/*" element={<RestrictedRoute component={MobileRoutes} />} />
      <Route path="demo/*" element={<RestrictedRoute component={DemoRoutes} />} />
      <Route path="signin" element={<Login />} />
      <Route path="*" element={<Error404 />} />
    </Routes>
  );
};

export default WmsRoutes;
