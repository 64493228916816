import React, { createContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchError, fetchStart, fetchSuccess } from 'redux/actions';
import { v4 as uuid } from 'uuid';

export const LoadingContext = createContext({
  isLoading: false,
  setLoading: () => {},
  on: () => {},
  off: () => {},
  start: options => {},
  error: (error, options) => {},
  success: (message, options) => {},
});

export const LoadingProvider = ({ children }) => {
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const on = () => {
    setLoading(true);
  };

  const off = () => {
    setLoading(false);
  };

  // fetchStart 는 string, object 두 가지 타입을 받을 수 있도록 처리 (in action)
  const start = options => {
    // useKey 옵션이 넘어온 경우 key와 함께 넘어왔으면 해당키로 없으면 랜덤키로
    if (options?.useKey === true) {
      options.key = options.key || uuid();
    }

    dispatch(fetchStart(options));

    // 키사용 true 일경우 키 리턴하기
    if (options?.useKey === true) {
      return options.key;
    }
  };

  const error = (error, options) => {
    dispatch(fetchError(error, options));
  };

  const success = (message, options) => {
    dispatch(fetchSuccess(message, options));
  };

  return (
    <LoadingContext.Provider value={{ isLoading, setLoading, on, off, start, error, success }}>
      {children}
    </LoadingContext.Provider>
  );
};

export default LoadingProvider;
