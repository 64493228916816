import React from 'react';
import { Navigate, useRoutes } from 'react-router';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import loadable from '@loadable/component';

const Return = loadable(() => import('./Return'));
const Receipt = loadable(() => import('./Receipt'));
const Status = loadable(() => import('./Status'));
const NotFound = loadable(() => import('routes/Errors/404'));

const WholesaleReturnRoutes = () => {
  const routes = [
    { index: true, element: <Navigate to="receipt" replace /> },
    { path: 'list', element: <Return /> },
    { path: 'receipt', element: <Receipt /> },
    { path: 'status', element: <Status /> },
    { path: '*', element: <NotFound /> },
  ];

  return <PageContainer>{useRoutes(routes)}</PageContainer>;
};

export default WholesaleReturnRoutes;
