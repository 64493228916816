import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import { Chip } from '@mui/material';
import { addPageHistory, deletePageHistory } from 'redux/actions/History';
import { useNavigate } from 'react-router';

export default function HistoryContext() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { page_history } = useSelector(({ history }) => history);
  const dispatch = useDispatch();
  const handleDelete = pathname => {
    dispatch(deletePageHistory(pathname));
  };
  const handleChipClick = path_info => {
    navigate(path_info.pathname);
    dispatch(addPageHistory(path_info));
  };
  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}>
        방문기록
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>
        {page_history.length > 0 ? (
          page_history.map(path_info => {
            return (
              <MenuItem
                key={path_info.pathname}
                onClick={() => {
                  handleChipClick(path_info);
                }}>
                <Chip
                  label={path_info.pathlabel}
                  onDelete={() => {
                    handleDelete(path_info.pathname);
                  }}
                />
              </MenuItem>
            );
          })
        ) : (
          <div />
        )}
      </Menu>
    </div>
  );
}
