import React, { useEffect, useState } from 'react';
import { LinearProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';

const GlobalProgress = () => {
  const { globalProgress } = useSelector(({ common }) => common);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (globalProgress !== null) {
      setProgress(globalProgress);
    } else {
      setProgress(0);
    }
  }, [globalProgress]);

  const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: '10px',
    background: '#fff',
    position: 'fixed',
    width: '100%',
    zIndex: 9999,
  }));

  if (globalProgress !== null) {
    return <StyledLinearProgress variant="determinate" value={progress} color="error" />;
  } else {
    return null;
  }
};

export default GlobalProgress;
