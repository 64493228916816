import { ADD_PAGE_HISTORY, SAVE_TABLE_STATE, DELETE_PAGE_HISTORY, PUSH_HISTORY } from '@jumbo/constants/ActionTypes';

const INIT_STATE = {
  page_history: [],
  push_history: { url: null, isMoved: false }, // store에 있는 url이 이전과 같은 경우 변화 감지가 불가하여 이동여부 판별용으로 isMoved 추가
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_PAGE_HISTORY: {
      const if_path_exist = state.page_history.filter(p => p.pathname === action.payload.pathname);

      if (if_path_exist.length > 0) {
        const history = state.page_history.filter(p => p.pathname !== action.payload.pathname);
        const exist_history = state.page_history.filter(p => p.pathname === action.payload.pathname);

        return {
          ...state,
          page_history: [...history, ...exist_history],
        };
      }
      if (state.page_history.length > 4) {
        state.page_history.shift();
        return {
          ...state,
          page_history: [...state.page_history, action.payload],
        };
      } else {
        return {
          ...state,
          page_history: [...state.page_history, action.payload],
        };
      }
    }

    case DELETE_PAGE_HISTORY: {
      const updated_page_history = state.page_history.filter(p => p.pathname !== action.payload);
      return {
        ...state,
        page_history: updated_page_history,
      };
    }

    case SAVE_TABLE_STATE: {
      const index = state.page_history.findIndex(p => p.pathname === action.payload.location);
      const _page_history = state.page_history;
      _page_history[index] = {
        ..._page_history[index],
        filter: action.payload.filter,
        column: action.payload.column,
      };

      return {
        ...state,
        page_history: _page_history,
      };
    }

    case PUSH_HISTORY: {
      const push_history = { url: action.url, isMoved: action.isMoved };
      return {
        ...state,
        push_history: push_history,
      };
    }

    default:
      return state;
  }
};
