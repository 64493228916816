import React from 'react';
import { useNavigate, useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { addPageHistory } from 'redux/actions/History';
import { Divider, Stack, Box } from '@mui/material';
import { NavLink } from 'react-router-dom';

const PageChildrenBtn = props => {
  const { children_menu } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const pathname = location.pathname;

  const addToHistory = menu => {
    const page_info = {
      pathname: menu.link,
      pathlabel: menu.name.props.children,
      filter: {},
    };
    dispatch(addPageHistory(page_info));

    // 메뉴 링크와 현재 주소가 같다면 새로고침 - 기존의 부모 클릭시에도 새로고침 되는것은 제거 (비정상작동)
    if (menu.link === pathname) {
      navigate(0);
    } else {
      navigate(menu.link);
    }
  };

  return (
    <Box style={{ background: 'white' }} mx={-2}>
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        spacing={1}
        style={{ padding: '9px 10px 8px 12rem' }}>
        {children_menu.map((menu, index) => {
          let parentLink = false;

          if (menu.children && pathname.includes(menu.link)) {
            parentLink = true;
          }

          const bold = pathname === menu.link || parentLink ? 'bold' : 'normal';
          const textColor = pathname === menu.link || parentLink ? '#009688' : '#999';
          const activeFocus = pathname === menu.link || parentLink ? '13' : '12';
          const border = pathname === menu.link || parentLink ? '2px solid #009688' : '0 solid #fff';

          return (
            <NavLink
              key={`child-menu-${index}`}
              to={menu.link}
              style={{
                color: textColor,
                fontSize: activeFocus,
                borderBottom: border,
                padding: '0 6px',
                fontWeight: bold,
                display: 'block',
              }}
              onClick={() => {
                addToHistory(menu);
              }}>
              <Box component="span">{menu.name}</Box>
            </NavLink>
          );
        })}
      </Stack>
      <Divider />
    </Box>
  );
};

export default PageChildrenBtn;
