// import Cookies from 'js-cookie';

/**
 * token을 상태별로 관리하기 위한 객체를 반환합니다.
 */
const getTokenMap = () => {
  if (typeof window === undefined) {
    return null;
  }

  try {
    const serializedTokenMap = localStorage.getItem('matrix.token');
    return serializedTokenMap ? JSON.parse(serializedTokenMap) : {};
  } catch (error) {
    console.error('matrix.token을 파싱할 수 없습니다.');
    return {};
  }
};

export const getToken = () => {
  if (typeof window === undefined) {
    return null;
  }

  const tokenMap = getTokenMap();

  return tokenMap[process.env.REACT_APP_ENV];
  //return Cookies.get('matrix.token');
};

export const setToken = token => {
  if (typeof window === undefined) {
    return null;
  }

  const tokenMap = getTokenMap();

  tokenMap[process.env.REACT_APP_ENV] = token;

  localStorage.setItem('matrix.token', JSON.stringify(tokenMap));
};

export const removeToken = () => {
  const tokenMap = getTokenMap();
  delete tokenMap[process.env.REACT_APP_ENV];
  localStorage.setItem('matrix.token', JSON.stringify(tokenMap));
};
