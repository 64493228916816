import {
  SET_RETAIL_RETURN_STATUS_CHECK,
  SET_RETAIL_RETURN_STATUS_PRODUCT,
  SET_RETAIL_RETURN_PROGRESS_DONE,
  SET_RETAIL_RETURN_PROGRESS_ACCEPTED,
  SET_RETAIL_RETURN_RECEIVING_INSPECTION,
  SET_RETAIL_RETURN_SELLER_CONTACT,
  SET_RETAIL_RETURN_RECEIVING_INSPECTION_DETAIL,
  SET_RETAIL_RETURN_RECEIVING_INSPECTION_DETAIL_POPUP,
  GET_CONSUMER_RETURN_INSPECTION_COMMENTS,
  SET_RETAIL_RETURN_RECEIVING_INSPECTION_DETAIL_CUSTOMER_RETURN,
  SET_ONLY_REGISTERED,
  SET_FORM_DATE_RANGE,
} from '@jumbo/constants/ActionTypes';

const INIT_STATE = {
  retail_status_check_list: [],
  retail_status_product_list: [],

  retail_progress_done_list: [],
  retail_progress_accepted_list: [],

  retail_receiving_inspection_list: [],
  retail_receiving_inspection_detail: null,
  retail_receiving_inspection_detail_popup: null,

  retail_seller_contact_list: [],
  consumer_return_comments: [],
  only_registered: false,
  form_date_range: null,
};

export default (state = INIT_STATE, action, dispatch) => {
  switch (action.type) {
    // due diligence
    case SET_RETAIL_RETURN_STATUS_CHECK: {
      return {
        ...state,
        retail_status_check_list: action.payload,
      };
    }
    case SET_RETAIL_RETURN_STATUS_PRODUCT: {
      return {
        ...state,
        retail_status_product_list: action.payload,
      };
    }
    case SET_RETAIL_RETURN_PROGRESS_DONE: {
      return {
        ...state,
        retail_progress_done_list: action.payload,
      };
    }
    case SET_RETAIL_RETURN_PROGRESS_ACCEPTED: {
      return {
        ...state,
        retail_progress_accepted_list: action.payload,
      };
    }
    case SET_RETAIL_RETURN_RECEIVING_INSPECTION: {
      return {
        ...state,
        retail_receiving_inspection_list: action.payload,
      };
    }
    case SET_RETAIL_RETURN_RECEIVING_INSPECTION_DETAIL: {
      return {
        ...state,
        retail_receiving_inspection_detail: action.payload,
      };
    }
    case SET_RETAIL_RETURN_RECEIVING_INSPECTION_DETAIL_CUSTOMER_RETURN: {
      return {
        ...state,
        retail_receiving_inspection_detail: {
          ...state.retail_receiving_inspection_detail,
          consumer_return: action.payload,
        },
      };
    }
    case SET_RETAIL_RETURN_RECEIVING_INSPECTION_DETAIL_POPUP: {
      return {
        ...state,
        retail_receiving_inspection_detail_popup: action.payload,
      };
    }
    case SET_RETAIL_RETURN_SELLER_CONTACT: {
      return {
        ...state,
        retail_seller_contact_list: action.payload,
      };
    }
    case SET_ONLY_REGISTERED: {
      return {
        ...state,
        only_registered: action.payload,
      };
    }
    case SET_FORM_DATE_RANGE: {
      return {
        ...state,
        form_date_range: action.payload,
      };
    }
    //관리자 메모 조회
    case GET_CONSUMER_RETURN_INSPECTION_COMMENTS: {
      return {
        ...state,
        consumer_return_comments: action.payload,
      };
    }
    default:
      return state;
  }
};
