export const SHOW_MESSAGE = 'show_message';
export const HIDE_MESSAGE = 'hide_message';
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const FETCH_RESET = 'fetch_reset';

export const UPDATE_AUTH_USER = 'update_auth_user';
export const UPDATE_LOAD_USER = 'update_load_user';
export const SEND_FORGET_PASSWORD_EMAIL = 'send_forget_password_email';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'signin_google_user_success';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'signin_facebook_user_success';
export const SIGNIN_TWITTER_USER_SUCCESS = 'signin_twitter_user_success';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_SUCCESS';
export const SIGNIN_USER_SUCCESS = 'signin_user_success';
export const SIGNOUT_USER_SUCCESS = 'signout_user_success';
// 설정관리 > 로케이션 관리
export const SET_LOCATIONS = 'set_locations';
export const RESET_LOCATIONS = 'reset_locations';
export const SET_LOCATIONS_SUMMARY = 'set_locations_summary';
export const SET_LOCATIONS_TYPES = 'set_locations_types';
export const SET_LOCATIONS_LANES = 'set_locations_lanes';
export const EDIT_LOCATION = 'edit_location';
export const EDIT_LOCATIONS = 'edit_locations';
export const EDIT_LOCATIONS_GROUP = 'edit_location_group';
export const DELETE_LOCATIONS = 'delete_location';
export const ADD_NEW_LOCATION = 'add_new_location';
export const ADD_NEW_LOCATIONS = 'add_new_locations';
export const SET_USER_ACCOUNTS = 'set_user_accounts';
export const ADD_NEW_ACCOUNT = 'add_new_account';
export const DELETE_ACCOUNT = 'delete_account';
export const EDIT_ACCOUNT_INFO = 'edit_account_info';
export const SET_PACKING_TABLE = 'set_packing_table';
export const SET_PACKING_TABLE_BY_ID = 'set_packing_table_by_id';
export const GET_SELLER_INFO = 'get_seller_info';
export const GET_PRODUCT_INFO = 'get_product_info';

// 설정관리 > 출고 > 출고테이블관리

// 설정관리 > 출고 > 출고테이블관리
export const SET_SETTING_SHIPMENT = 'set_setting_shipment';
export const SET_SHIPMENT = 'set_shipment';
export const SET_WAREHOUSING = 'set_warehousing';
export const ADD_NEW_SHIPMENT_TABLE = 'add_new_shipment_table';
export const EDIT_SHIPMENT_TABLE = 'edit_shipment_table';
export const DELETE_SHIPMENT_TABLE = 'delete_shipment_table';
export const SET_SHIPMENT_TABLE = 'set_shipment_table';
export const SET_ONE_SHIPMENT_TABLE = 'set_one_shipment_table';
export const GET_USERS_INFO = 'get_users_info';
export const GET_ALL_SHIPMENTTABLE_ASSIGNED_SELLERS = 'get_all_shipment_table_assigned_sellers';
export const SET_SETTING_SHIPMENT_TABLE = 'set_setting_shipment_table';

// 사입입고
export const SET_WAREHOUSING_PURCHASE = 'set_warehousing_purchase';
export const SET_WAREHOUSING_STANDBY = 'set_warehousing_standby';
export const SET_WAREHOUSING_STANDBY_SUMMARY = 'set_warehousing_standby_summary';
export const SET_WAREHOUSING_INSPECTION_TAB = 'set_warehousing_inspection_tab';
export const SET_WAREHOUSING_INSPECTION_PURCHASE = 'set_warehousing_inspection_purchase';
export const EDIT_WAREHOUSING_INSPECTION_PURCHASE = 'edit_warehousing_inspection_purchase';
export const SET_WAREHOUSING_INSPECTION_MISONG = 'set_warehousing_inspection_misong';
export const EDIT_WAREHOUSING_INSPECTION_MISONG = 'edit_warehousing_inspection_misong';
export const EDIT_PRICE_WAREHOUSING_INSPECTION_PURCHASE = 'edit_price_warehousing_inspection_purchase';
export const EDIT_STOCK_WAREHOUSING_INSPECTION_PURCHASE = 'edit_stock_warehousing_inspection_purchase';
export const EDIT_JANGKKIGROUP_WAREHOUSING_INSPECTION_PURCHASE = 'edit_jangkkigroup_warehousing_inspection_purchase';
export const EDIT_JANGKKI_WAREHOUSING_INSPECTION_PURCHASE = 'edit_jangkki_warehousing_inspection_purchase';
export const EDIT_PIECEGROUP_WAREHOUSING_INSPECTION_PURCHASE = 'edit_piecegroup_warehousing_inspection_purchase';
export const EDIT_PIECE_WAREHOUSING_INSPECTION_PURCHASE = 'edit_piece_warehousing_inspection_purchase';
export const EDIT_INSTOCKGROUP_WAREHOUSING_INSPECTION_PURCHASE = 'edit_instockgroup_warehousing_inspection_purchase';
export const EDIT_INSTOCK_WAREHOUSING_INSPECTION_PURCHASE = 'edit_instock_warehousing_inspection_purchase';
export const SET_WAREHOUSING_CONFIRM = 'set_warehousing_confirm';
export const SET_WAREHOUSING_CONFIRM_SUMMARY = 'set_warehousing_confirm_summary';
export const PUT_CONFIRM_BY_ID = 'put_confirm_by_id';
export const PUT_CONFIRM_BY_OWNER = 'put_confirm_by_owner';
export const PUT_CONFIRM_ALL = 'put_confirm_all';
export const SET_WAREHOUSING_FIXED = 'set_warehousing_fixed';
export const SET_WAREHOUSING_FIXED_SUMMARY = 'set_warehousing_fixed_summary';
export const SET_WAREHOUSING_TRACKING = 'set_warehousing_tracking';
export const SET_WAREHOUSING_TRACKING_PAGINATION = 'set_warehousing_tracking_pagination';
export const SET_WAREHOUSING_TRACKING_FILTER = 'set_warehousing_tracking_filter';
export const SET_SORTING_WAREHOUSING_TRACKING_CONFIRMABLE = 'set_sorting_warehousing_tracking_confirmable';
export const SET_SORTING_WAREHOUSING_TRACKING_CONFIRMDISABLE = 'set_sorting_warehousing_tracking_confirmdisable';
export const CANCEL_FORCESTOCK_WAREHOUSING_TRACKING = 'cancel_forcestock_warehousing_tracking';
export const SET_WAREHOUSING_DETAIL_INFORMATION = 'set_warehousing_detail_information';
export const DELETE_WAREHOUSING_DETAIL_INFORMATION = 'delete_warehousing_detail_information';
export const SET_WAREHOUSING_WHOLESALE_LIST = 'set_warehousing_wholesale_list';

// 직접입고
export const GET_PACKING_TABLE_ARRIVE = 'get_packing_table_arrive';
export const GET_PACKING_TABLE_PRODUCT = 'get_packing_table_product';
export const GET_REPAIR_TABLE_ARRIVE = 'get_repair_table_arrive';
export const GET_REPAIR_TABLE_PRODUCT = 'get_repair_table_product';
export const GET_WHOLESALE_RETURN_TABLE_PRODUCT = 'get_wholesale_return_table_product';
export const GET_SELLER_CONFIRM_TABLE_ARRIVE = 'get_seller_confirm_table_arrive';
export const GET_SELLER_CONFIRM_TABLE_ARRIVE_HISTORY = 'get_seller_confirm_table_arrive_history';
export const GET_SELLER_CONFIRM_TABLE_ARRIVE_MANY = 'get_seller_confirm_table_arrive_many';
export const GET_SELLER_CONFIRM_TABLE_ARRIVE_MANY_UNSELECTED_LIST = 'get_seller_confirm_table_arrive_many_unselected_list';
export const GET_SELLER_CONFIRM_TABLE_ARRIVE_MANY_SELECTED_LIST = 'get_seller_confirm_table_arrive_many_selected_list';
export const GET_ISSUE_SUPPLIER_RETURN_HISTORY = 'get_issue_supplier_return_history';
export const GET_SELLER_CHECK_TABLE_PRODUCT = 'get_seller_check_table_product';
export const GET_WHOLESALE_RETURN_TABLE_ARRIVE = 'get_wholesale_return_table_arrive';
export const GET_WHOLESALE_RETURN_TABLE_ARRIVE_HISTORY = 'get_wholesale_return_table_arrive_history';
export const GET_AFTER_WHOLESALE_RETURN_TABLE_ARRIVE = 'get_after_wholesale_return_table_arrive';
export const GET_WHOLESALE_RETURN_TABLE_ARRIVE_MANY = 'get_wholesale_return_table_arrive_many';
export const GET_WHOLESALE_RETURN_TABLE_ARRIVE_MANY_UNSELECTED_LIST =
  'get_wholesale_return_table_arrive_many_unselected_list';
export const GET_WHOLESALE_RETURN_TABLE_ARRIVE_MANY_SELECTED_LIST = 'get_wholesale_return_table_arrive_many_selected_list';
export const GET_DIRECT_SELLER_CONFIRM_TABLE_ARRIVE = 'get_direct_seller_confirm_table_arrive';
export const GET_DIRECT_SELLER_CONFIRM_TABLE_PRODUCT = 'get_direct_seller_confirm_table_product';
export const SAVE_PRODUCT_HISTORY = 'save_product_history';
export const SET_TASK_CURRENT_USER = 'set_task_current_user';
export const SET_TASK_LIST_DATA = 'set_task_list_data';
export const ADD_TASK = 'add_task';
export const DELETE_TASK = 'delete_task';
export const UPDATE_TASK = 'update_task';
export const SET_FILTER_DATA = 'set_filter_data';
export const ADD_TASK_LIST = 'add_task_list';
export const UPDATE_TASK_LIST = 'update_task_list';
export const DELETE_TASK_LIST = 'delete_task_list';
export const SET_TASK_DETAIL = 'set_task_detail';
export const SEND_MESSAGE = 'send_message';
export const TOGGLE_SIDEBAR_COLLAPSED = 'toggle_sidebar_collapsed';
export const GET_TASKS_COUNTS = 'get_tasks_counts';
export const GET_SELF_WAREHOUSING_PURCHASE_LIST = 'get_self_warehousing_purchase_list';
export const GET_SELF_WAREHOUSING_WAITING_LIST = 'get_self_warehousing_waiting_list';
export const GET_SELF_WAREHOUSING_REQUEST_DETAIL = 'get_self_warehousing_request_detail';
export const GET_SELF_WAREHOUSING_REQUEST_DETAIL_LIST = 'get_self_warehousing_request_detail_list';
export const EDIT_SELF_WAREHOUSING_REQUEST_DETAIL_LIST = 'edit_self_warehousing_request_detail_list';
export const GET_SELF_WAREHOUSING_PER_PRODUCT_LIST = 'get_self_warehousing_per_product_list';
export const GET_SELF_WAREHOUSING_PER_ROTATE_LIST = 'get_self_warehousing_per_rotate_list';
export const SET_MOVINGLOCATION_ALL_LIST = 'set_movinglocation_all_list';
export const SET_MOVINGLOCATION_LIST = 'set_movinglocation_list';
export const SET_MOVINGHISTORY_LIST = 'set_movinghistory_list';
export const SET_CHANGED_INSTOCK = 'set_changed_instock';
export const GET_WHOLESALE_RETURN_PRODUCT_HISTORY = 'get_wholesale_return_product_history';
export const GET_SELLER_CONFIRM_PRODUCT_HISTORY = 'get_seller_confirm_product_history';
export const GET_RESET_TABLE_MOVE_INFO = 'get_reset_table_move_info';
// 출고관리
export const SET_STATUS_CHECK = 'set_status_check';
export const SET_STATUS_CHECK_PAGINATION = 'set_status_check_pagination';
export const SET_STATUS_CHECK_FILTER = 'set_status_check_filter';
export const SET_TARGET_STANDBY_CHECK = 'set_target_standby_check';
export const SET_TARGET_FAILURE_CHECK = 'set_target_failure_check';
export const SET_TARGET_HOLD_CHECK = 'set_target_hold_check';
export const SET_CANCEL_COMPLETE = 'set_target_cancel_complete';
export const SET_CANCEL_COMPLETE_DETAIL = 'set_target_cancel_complete_detail';
export const SET_ROTATE_CHECK = 'set_rotate_check';
export const SET_ROTATE_CHECK_DETAIL = 'set_rotate_check_detail';
export const SET_WAVE_COUNT_INFO = 'set_wave_count_info';
export const SET_ORDER_CHECK = 'set_order_check';
export const SET_SHIPPED_DAS = 'set_shipped_das';
export const SET_SHIPPED_DIRECT = 'set_shipped_direct';
export const SET_INVOICE_ORDER = 'set_invoice_order';
export const SET_PRINT_PICKING_LIST = 'set_print_picking_list';
export const RESET_PRINT_PICKING_LIST = 'reset_print_picking_list';

// 출고관리 > DAS 피킹
export const INIT_DAS_PICKING = 'init_das_picking'; // DAS 피킹 > 전역상태 초기화
export const SET_DAS_PICKING = 'set_das_picking'; // DAS 피킹 > 피킹 리스트 바코드 스캔 정보 세팅
export const SET_DAS_PICKING_STATE = 'set_das_picking_state'; // DAS 피킹 > 피킹 상태 세팅
export const SET_DAS_PICKING_HISTORY = 'set_das_picking_history'; // DAS 피킹 > 상품 바코드 스캔 이력 세팅
export const CHANGE_PENDING_STATUS = 'change_pending_status'; // DAS 피킹 > 출고보류/해제시 렉 상태 변경(TODO: 출고 보류시 렉 상태 변경 확인 필요)
export const ADD_SELECTED_ORDER = 'add_selected_order'; // DAS 피킹 > 선택된 셀 목록 추가
export const REMOVE_SELECTED_ORDER = 'remove_selected_order'; // DAS 피킹 > 선택된 셀 목록 제거
export const TOGGLE_SELECTED_ORDER = 'toggle_selected_order'; // DAS 피킹 > 선택된 셀 목록 토글
export const SET_TARGET_ORDER = 'set_target_order'; // DAS 피킹 > 적재대상 셀 세팅
export const SET_PRODUCT_CODES_LIST = 'set_product_codes_list'; //DAS 피킹 > 상품바코드 스캔 시 상품코드 선택 모달

// 출고관리 > 출고 처리
export const SET_SHIPPED_DESK = 'set_shipped_desk'; // 나의 작업 테이블 정보

// 출고관리 > 출고 처리 > DAS 출고
export const INIT_SHIPPED_DAS = 'init_SHIPPED_DAS'; // DAS 출고 상태 초기화
export const SET_SHIPPED_DAS_PICKING = 'set_shipped_das_picking'; // DAS 출고 피킹지 바코드
export const INIT_SHIPPED_DAS_INVOICE = 'init_shipped_das_invoice'; // DAS 출고 > 송장 정보 초기화
export const SET_SHIPPED_DAS_INVOICE = 'set_shipped_das_invoice'; // DAS 출고 > 송장 바코드

// 출고관리 > 출고 처리 > 바로 출고
export const INIT_SHIPPED_DIRECT = 'init_shipped_direct'; // 바로 출고 > 초기화
export const SET_SHIPPED_DIRECT_PICKING = 'set_shipped_direct_picking'; // 바로 출고 > 피킹지 바코드
export const INIT_SHIPPED_DIRECT_PRODUCT = 'init_shipped_direct_product'; // 바로 출고 > 상품 바코드 초기화
export const SET_SHIPPED_DIRECT_PRODUCT = 'set_shipped_direct_product'; // 바로 출고 > 상품 바코드
export const SET_SHIPPED_DIRECT_PRODUCT_CODE_LIST = 'set_shipped_direct_product_code_list'; // 바로 출고 > 상품 바코드 스캔 시 상품코드 여러개일 경우
export const SET_SHIPPED_DIRECT_INVOICE = 'set_shipped_direct_invoice'; // 바로 출고 > 송장 바코드

//출고 관리 > 출고 처리 > 오토백 출고
export const SET_AUTOBAG_PICKING_WAVE = 'set_autobag_picking_wave';
export const SET_AUTUBAG_PRODUCT_LIST = 'set_autobag_product_list';
export const SET_AUTOBAG_PRODUCT_COMPLETED = 'set_autobag_product_completed';
export const SET_AUTOBAG_PRODUCT_INCOMPLETED = 'set_autobag_product_incompleted';

// 출고관리 > 출고 처리 > 개별 출고
export const INIT_SHIPPED_ORDERS = 'init_shipped_orders'; // 개별 출고 상태 초기화
export const SET_SHIPPED_ORDERS = 'set_shipped_orders'; // 개별 출고 송장 바코드
export const SET_SHIPPED_ORDERS_PRODUCT_CODE_LIST = 'set_shipped_orders_product_code_list'; // 개별 출고 > 상품 바코드 스캔 시 상품코드 여러개일 경우

// 반품관리
export const SET_WHOLESALE_RETURN = 'set_wholesale_return';
export const SET_WHOLESALE_RETURN_RECEIPT = 'set_wholesale_return_receipt';
export const SET_WHOLESALE_RETURN_RECEIPT_PRINT_PICKING_LIST = 'set_wholesale_return_receipt_print_picking_list';
export const RESET_WHOLESALE_RETURN_RECEIPT_PRINT_PICKING_LIST = 'reset_wholesale_return_receipt_print_picking_list';
export const UPDATE_WHOLESALE_RETURN_RECEIPT = 'update_wholesale_return_receipt';
export const SET_WHOLESALE_RETURN_STATUS = 'set_wholesale_return_status';
// 회수관리
export const SET_RETAIL_RETURN_STATUS_CHECK = 'set_retail_return_status_check';
export const SET_RETAIL_RETURN_STATUS_PRODUCT = 'set_retail_return_status_product';
export const SET_RETAIL_RETURN_PROGRESS_ACCEPTED = 'set_retail_return_progress_accepted';
export const SET_RETAIL_RETURN_PROGRESS_DONE = 'set_retail_return_progress_done';
export const SET_RETAIL_RETURN_RECEIVING_INSPECTION = 'set_retail_return_receiving_inspection';
export const SET_RETAIL_RETURN_RECEIVING_INSPECTION_DETAIL = 'set_retail_return_receiving_inspection_detail';
export const SET_RETAIL_RETURN_RECEIVING_INSPECTION_DETAIL_CUSTOMER_RETURN =
  'set_retail_return_receiving_inspection_detail_customer_return';
export const SET_RETAIL_RETURN_RECEIVING_INSPECTION_DETAIL_POPUP = 'set_retail_return_receiving_inspection_detail_popup';
export const GET_CONSUMER_RETURN_INSPECTION_COMMENTS = 'get_consumer_return_inspection_comments';
export const SET_RETAIL_RETURN_SELLER_CONTACT = 'set_retail_return_seller_contact';
export const SET_DAS_INVOICE = 'set_das_invoice';
export const SET_DAS = 'set_das';
export const SET_DIRECT_PICKING = 'set_direct_picking';
export const SET_DIRECT = 'set_direct';
export const SET_ORDERS_INVOICE = 'set_orders_invoice';
export const SET_ORDERS = 'set_orders';

// export const SET_DAS = 'set_das';
// export const SET_DIRECT_PICKING = 'set_direct_picking';
// export const SET_DIRECT = 'set_direct';
// export const SET_ORDERS_INVOICE = 'set_orders_invoice';
// export const SET_ORDERS = 'set_orders';

// 상품관리
export const SET_INVENTORY_CHECK = 'set_inventory_check';
export const SET_INVENTORY_CHECK_FILTER = 'set_inventory_check_filter';
export const SET_INVENTORY_CHECK_PAGINATION = 'set_inventory_check_pagination';
export const SET_INVENTORY_CHECK_DETAIL = 'set_inventory_check_detail';
export const SET_SELLER_CHECK = 'set_seller_check';
export const SET_SELLER_SIMPLE_LIST = 'set_seller_simple_list';
export const SET_SELLER_CHECK_DETAIL = 'set_seller_check_detail';
export const SET_PRODUCT_INOUT = 'set_product_inout';
export const SET_REQUEST_DECREASE = 'set_request_decrease';
export const SET_REQUEST_DECREASE_INVENTORY_LIST = 'set_request_decrease_inventory_list';
export const SET_REQUEST_INCREASE = 'set_request_increase';
export const SET_REQUEST_INCREASE_INVENTORY = 'set_request_increase_inventory';
export const SET_REQUEST_STATE = 'set_request_state';

// 재고관리
export const SET_INVENTORY_DUE_DILIGENCE = 'set_inventory_due_diligence';
export const SET_INVENTORY_DUE_DILIGENCE_PAGINATION = 'set_inventory_due_diligence_pagination';
export const SET_INVENTORY_DUE_DILIGENCE_FILTER = 'set_inventory_due_diligence_filter';
export const SET_INVENTORY_LOCATIONS = 'set_inventory_locations';
export const SET_INVENTORY_LOCATIONS_FILTER = 'set_inventory_locations_filter';
export const SET_INVENTORY_LOCATIONS_PAGINATION = 'set_inventory_locations_pagination';
export const SET_INVENTORY_LOCATIONS_SEARCH_INFO = 'set_inventory_locations_search_info';
export const SET_SKU_SUPPLIER_RETURN = 'set_sku_supplier_return';

export const SET_INVENTORY_HISTORY = 'set_inventory_history';
export const SET_INVENTORY_HISTORY_SN = 'set_inventory_history_sn';
export const SET_HISTORY_IO_SUMMARY = 'set_history_io_summary';

// history
export const ADD_PAGE_HISTORY = 'add_page_history';
export const DELETE_PAGE_HISTORY = 'delete_page_history';
export const SAVE_TABLE_STATE = 'save_table_state';
export const PUSH_HISTORY = 'push_history';

export const SET_DASHBOARD_DATA = 'set_dashboard_data';

// mail app
export const GET_LABELS_LIST = 'get_labels_list';
export const GET_CONNECTIONS_LIST = 'get_connections_list';
export const GET_MAILS_LIST = 'get_mails_list';
export const UPDATE_MAIL_FOLDER = 'update_mail_folder';
export const UPDATE_MAIL_LABEL = 'upade_mail_label';
export const UPDATE_FAVORITE_STATUS = 'update_favorite_status';
export const UPDATE_READ_STATUS = 'update_read_status';
export const UPDATE_IMPORTANT_STATUS = 'update_important_status';
export const COMPOSE_MAIL = 'compose_mail';
export const SET_FILTER_TYPE = 'set_filter_type';
export const GET_SELECTED_MAIL = 'GET_SELECTED_MAIL';
export const UPDATE_SELECTED_MAIL = 'update_selected_mail';
export const NULLIFY_SELECTED_MAIL = 'nullify_selected_mail';
export const REPLY_TO_MAIL = 'reply_to_mail';
export const GET_MAIL_COUNTS = 'get_mail_count';
export const ADD_LABEL = 'add_label';
export const ADD_CONNECTION = 'add_connection';
export const REMOVE_CONNECTION = 'remove_connection';
export const SET_CHAT_USERS = 'set_chat_users';
export const SET_CONTACT_USERS = 'set_contact_users';
export const SET_CURRENT_USER = 'set_current_user';
export const SET_CONVERSATION_DATA = 'set_conversation_data';
export const SEND_NEW_CHAT_MESSAGE = 'send_new_chat_message';
export const SEND_NEW_MEDIA_MESSAGE = 'send_new_media_message';

// Contact App
export const GET_CONTACTS_LIST = 'get_contacts_list';
export const SET_CURRENT_CONTACT = 'set_current_contact';
export const CREATE_CONTACT = 'create_contact';
export const UPDATE_STARRED_STATUS = 'update_starred_status';
export const DELETE_CONTACT = 'delete_contact';
export const UPDATE_CONTACT_LABEL = 'update_contact_label';
export const UPDATE_CONTACT = 'update_contact';
export const GET_CONTACT_COUNTS = 'get_contact_counts';
export const UPDATE_LABEL_ITEM = 'update_label_item';
export const DELETE_LABEL_ITEM = 'delete_label_item';

export const GET_USER_DETAIL = 'get_user_detail';
export const GET_FEED_POSTS = 'get_feed_posts';
export const CREATE_POST = 'create_post';
export const UPDATE_POST = 'update_post';

// Users Module
export const GET_USERS = 'GET_USERS';
export const ADD_USER = 'ADD_USER';
export const SET_USER_DETAILS = 'SET_USER_DETAILS';
export const EDIT_USER = 'EDIT_USER';
export const DELETE_USER = 'DELETE_USER';
export const DELETE_BULK_USERS = 'DELETE_BULK_USERS';

// real packing
export const SET_REAL_PACKING_STATUS = 'set_real_packing_status';

export const GET_RETURN_PRODUCT_MANAGEMENT = 'get_return_product_management';
export const DELETE_RETURN_PRODUCT_MANAGEMENT = 'delete_return_product_management';
export const SET_ONLY_REGISTERED = 'set_only_registered';
export const SET_FORM_DATE_RANGE = 'set_form_date_range';
export const DELETE_SELLER_CONFIRM_TABLE_ARRIVE_MANY = 'delete_seller_confirm_table_arrive_many';
export const DELETE_DIRECT_SELLER_CONFIRM_TABLE_ARRIVE = 'delete_direct_seller_confirm_table_arrive';
export const SET_WAVE_PER_PRODUCT = 'set_wave_per_product';

export const SET_NOT_RESULT = 'set_not_result';

export const SET_FORM_DATE_RANGE_NOW_OUT = 'set_form_date_range_now_out';
export const SET_NOT_REGISTER = 'set_not_register';
