import { FETCH_ERROR, FETCH_RESET, FETCH_START, FETCH_SUCCESS } from '@jumbo/constants/ActionTypes';

export const fetchSuccess = (message, options) => {
  return dispatch => {
    dispatch({
      type: FETCH_SUCCESS,
      message: message, // 성공 메시지 (기본값: '')
      key: options?.key, // 로딩 키
    });
  };
};
export const fetchError = (error, options) => {
  return dispatch => {
    dispatch({
      type: FETCH_ERROR,
      error: error, // 실패/에러 메시지
      key: options?.key, // 로딩 키
      useAlert: options?.useAlert, // 에러 메시지를 alert로 보여줄지 여부 (기본값: false)
    });
  };
};
// fetchStart 는 메시지 없이 사용한 경우가 워낙 많아서 string, object 두 가지 타입을 받을 수 있도록 처리
export const fetchStart = params => {
  let options = {};
  if (typeof params === 'string') {
    options.loadingMessage = params;
  } else if (typeof params === 'object') {
    options = params;
  }

  return dispatch => {
    dispatch({
      type: FETCH_START,
      loadingMessage: options?.loadingMessage, // 로딩중 메시지 (기본값: '')
      key: options?.key, // 로딩 키
    });
  };
};

// 로딩 초기화
export const fetchReset = options => {
  return dispatch => {
    dispatch({
      type: FETCH_RESET,
      key: options?.key, // 로딩 키
      // 나머지는 기본값 처리 (message, error, useAlert)
    });
  };
};
