import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  loggerOnOff: {
    color: 'white',
  },
}));

const ReduxLogOnOffForLocal = () => {
  const classes = useStyles();

  const [isReduxLogOn, setIsReduxLogOn] = useState(localStorage.getItem('isReduxLogOn'));

  if (isReduxLogOn === null || isReduxLogOn === undefined) {
    localStorage.setItem('isReduxLogOn', 'true');
  }

  const onReduxLogOnOff = e => {
    const nextValue = `${!(isReduxLogOn === 'true')}`;
    setIsReduxLogOn(nextValue);
    localStorage.setItem('isReduxLogOn', nextValue);

    window.location.reload();
  };

  return (
    <React.Fragment>
      {/* 로컬용 리덕스 로그 on / off 스위치 - 주의 localStorage 는 string */}
      <FormControlLabel
        className={classes.loggerOnOff}
        control={<Switch checked={isReduxLogOn === 'true'} onChange={onReduxLogOnOff} color={'warning'} />}
        label={isReduxLogOn === 'true' ? 'Redux Log On' : 'Redux Log Off'}
      />
    </React.Fragment>
  );
};

export default ReduxLogOnOffForLocal;
