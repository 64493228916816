import {
  SET_INVENTORY_DUE_DILIGENCE, // 상품재고조회
  SET_PRODUCT_INOUT,
  SET_INVENTORY_LOCATIONS, // 현재고조회
  SET_INVENTORY_HISTORY, // 상품 입출고 내역 조회
  SET_INVENTORY_HISTORY_SN,
  SET_INVENTORY_DUE_DILIGENCE_PAGINATION,
  SET_INVENTORY_DUE_DILIGENCE_FILTER,
  SET_INVENTORY_LOCATIONS_FILTER,
  SET_INVENTORY_LOCATIONS_PAGINATION, // 시리얼넘버 처리내역 조회
  SET_INVENTORY_LOCATIONS_SEARCH_INFO, // 검색 정보
  SET_SKU_SUPPLIER_RETURN, // 도매반품 예정수량
} from '@jumbo/constants/ActionTypes';

const INIT_STATE = {
  inventory_due_diligence: [],
  inventory_due_diligence_pagination: null,
  inventory_due_diligence_filter: null,
  inventory_due_diligence_search_info: '검색을 진행해 주세요',
  inventory_history: [],
  inventory_history_sn: { data: null, histories: [] },
  product_inout: {},
  inventory_locations: [],
  inventory_locations_filter: null,
  inventory_locations_pagination: null,
  inventory_locations_search_info: '검색을 진행해 주세요',
  sku_supplier_return: [],
};

export default (state = INIT_STATE, action, dispatch) => {
  switch (action.type) {
    // due diligence
    case SET_INVENTORY_DUE_DILIGENCE: {
      return {
        ...state,
        inventory_due_diligence: action.payload,
      };
    }
    case SET_INVENTORY_DUE_DILIGENCE_PAGINATION: {
      return {
        ...state,
        inventory_due_diligence_pagination: action.payload,
      };
    }
    case SET_INVENTORY_DUE_DILIGENCE_FILTER: {
      return {
        ...state,
        inventory_due_diligence_filter: action.payload,
      };
    }

    // 상품 입출고 조회 내역
    case SET_PRODUCT_INOUT: {
      return {
        ...state,
        product_inout: action.payload,
      };
    }
    // 현재고조회
    case SET_INVENTORY_LOCATIONS: {
      return {
        ...state,
        inventory_locations: action.payload,
      };
    }
    case SET_INVENTORY_LOCATIONS_FILTER: {
      return {
        ...state,
        inventory_locations_filter: action.payload,
      };
    }
    case SET_INVENTORY_LOCATIONS_PAGINATION: {
      return {
        ...state,
        inventory_locations_pagination: action.payload,
      };
    }
    case SET_INVENTORY_LOCATIONS_SEARCH_INFO: {
      return {
        ...state,
        inventory_locations_search_info: action.payload,
      };
    }
    case SET_INVENTORY_HISTORY: {
      return {
        ...state,
        inventory_history: action.payload,
      };
    }
    case SET_INVENTORY_HISTORY_SN: {
      return {
        ...state,
        inventory_history_sn: action.payload,
      };
    }
    case SET_SKU_SUPPLIER_RETURN: {
      return {
        ...state,
        sku_supplier_return: action.payload,
      };
    }
    default:
      return state;
  }
};
