import { FETCH_ERROR, FETCH_RESET, FETCH_START, FETCH_SUCCESS } from '@jumbo/constants/ActionTypes';

const INIT_STATE = {
  actionType: FETCH_START,
  initialURL: '/',
  error: '', // 로딩 실패/에러 메시지
  message: '', // 로딩 성공 메시지
  loading: false, // 로딩 여부
  loadingMessage: '', // 로딩중 메시지
  key: '', // 현재 키
  keys: [], // 로딩 키 배열
  useAlert: false, // window.alert() 사용 여부 (화면을 정지시켜 작업자에게 인지시키기 위해 사용)
  globalProgress: null, // 전역 프로그레스바 사용 여부
};

/**
 * 로딩 키 기반 상태 변경 공통화
 *
 * 다른 로딩에 의하여 사용자 입력방지 화면이 캔슬되면 안될때 로딩키 사용
 * 주의 : 로딩 키 사용시 해당 키로 해제(fetchSuccess, fetchError)되지 않으면 로딩중 상태가 계속 유지됨!
 * @param state
 * @param action
 * @returns state
 */
const keyBasedLoadingState = (state, action) => {
  if (action.type === FETCH_START) {
    // 로딩키 추가
    if (action.key && !state.keys.find(k => k === action.key)) state.keys.push(action.key);

    // 로딩중 상태 변경
    state.loading = true;
  } else {
    // 로딩키 제거
    if (action.key) state.keys = state.keys.filter(k => k !== action.key);

    // 키가 비어있을때만 로딩중 false
    if (state.keys.length === 0) {
      // 로딩중 상태 변경
      state.loading = false;
    }
  }

  return state;
};

/**
 * 각 로딩 상태별 메시지 컨트롤 공통화 (로딩중 메시지, 성공 메시지, 에러 메시지)
 * @param state
 * @param action
 * @returns state
 */
const stateMsgControl = (state, action) => {
  // FETCH_RESET 타입일경우 모두 리셋
  state.loadingMessage = action.type === FETCH_START && !!action.loadingMessage ? action.loadingMessage : '';
  state.message = action.type === FETCH_SUCCESS && !!action.message ? action.message : '';
  state.error = action.type === FETCH_ERROR && !!action.error ? action.error : '';

  return state;
};

const manageState = (state, action) => {
  state.useAlert = action.useAlert === true || false; // window.alert() 사용 여부 (화면을 정지시켜 작업자에게 인지시키기 위해 사용)
  state.actionType = action.type;
  state.key = action.key;
  return stateMsgControl(keyBasedLoadingState(state, action), action);
};

const setGlobalProgress = (state, action) => {
  state.globalProgress = action.payload;
  return state;
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_START:
    case FETCH_SUCCESS:
    case FETCH_ERROR:
    case FETCH_RESET: {
      return { ...manageState(state, action) };
    }
    case 'set_global_progress':
      return { ...setGlobalProgress(state, action) };
    default:
      return state;
  }
};
